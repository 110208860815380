export
    const
    courts = {
        'Malaysia': {
            Alphabet: ["C", "F", "H", "M", "S"],
            courts: [{
                'hierarchy': 'Superior Courts',
                'courts': [{ 'name': 'Court of Appeal of Malaysia', 'id': 2 },
                { 'name': 'Federal Court of Malaysia', 'id': 1 },
                { 'name': 'High Courts of Malaysia', 'id': 3 }]
            },
            {
                'hierarchy': 'Subordinate Courts',
                'courts': [{ 'name': 'Magistrates Courts of Malaysia', 'id': 5 },
                { 'name': 'Sessions Courts of Malaysia', 'id': 4 }]
            }]
        },
        'Singapore': {
            Alphabet: ['C', 'D', 'H', 'M'],
            courts: [{
                'hierarchy': 'Supreme Courts',
                'courts': [{ 'name': 'Court of Appeal 2005', 'id': 'SGCA' },
                { 'name': 'High Court 2005', 'id': 'SGHC' }]
            },
            {
                'hierarchy': 'State Courts',
                'courts': [{ 'name': 'District Court of Singapore 2006', 'id': 'SGDC' },
                { 'name': "Magistrates' Court of Singapore 2006", 'id': 'SGMC' }]
            }]
        },
        'United Kingdom': {
            Alphabet: ['A',
                'C',
                'E',
                'F',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'P',
                'Q',
                'S',
                'T',
                'V'],
            courts: [{
                'hierarchy': 'Supreme Courts',
                'courts': [{ 'name': 'House of Lords Decisions', 'id': 'uk/cases/UKHL' },
                { 'name': 'St Helena Supreme Court', 'id': 'sh/cases/SHSC' },
                { 'name': 'Supreme Court Decisions', 'id': 'uk/cases/UKSC' },
                { 'name': 'Supreme Court of Ireland Decisions', 'id': 'ie/cases/IESC' }]
            },
            {
                'hierarchy': 'Court of Appeal',
                'courts': [{
                    'name': 'Court of Appeal (Civil Division) Decisions',
                    'id': 'ew/cases/EWCA/Civ'
                },
                {
                    'name': 'Court of Appeal (Criminal Division) Decisions',
                    'id': 'ew/cases/EWCA/Crim'
                },
                {
                    'name': 'Court of Appeal in Northern Ireland Decisions',
                    'id': 'nie/cases/NICA'
                },
                {
                    'name': 'England and Wales: Court of Appeal Decisions',
                    'id': 'ew/cases/EWCA'
                },
                { 'name': 'Irish Court of Appeal', 'id': 'ie/cases/IECA' },
                { 'name': 'Irish Court of Criminal Appeal', 'id': 'ie/cases/IECCA' },
                {
                    'name': 'Scottish Sheriff Appeal Courts (Civil Division) Decisions',
                    'id': 'scot/cases/ScotSAC/Civ'
                },
                {
                    'name': 'Scottish Sheriff Appeal Courts (Criminal Division) Decisions',
                    'id': 'scot/cases/ScotSAC/Crim'
                }]
            },
            {
                'hierarchy': 'High Courts',
                'courts': [{
                    'name': 'Admiralty Division Decisions',
                    'id': 'ew/cases/EWHC/Admlty'
                },
                { 'name': 'Chancery Division Decisions', 'id': 'ew/cases/EWHC/Ch' },
                { 'name': 'Chancery Division Decisions', 'id': 'nie/cases/NIHC/Ch' },
                { 'name': 'Commercial Court Decisions', 'id': 'ew/cases/EWHC/Comm' },
                {
                    'name': 'England and Wales County Court (Family)',
                    'id': 'ew/cases/EWCC/Fam'
                },
                { 'name': 'England and Wales Family Court', 'id': 'ew/cases/EWFC' },
                {
                    'name': 'England and Wales Family Court (High Court Judges)',
                    'id': 'ew/cases/EWFC/HCJ'
                },
                {
                    'name': 'England and Wales Family Court (Other Judges)',
                    'id': 'ew/cases/EWHC/OJ'
                },
                {
                    'name': "England and Wales Magistrates' Court (Family)",
                    'id': 'ew/cases/EWMC/FPC'
                },
                {
                    'name': 'England and Wales: High Court Decisions',
                    'id': 'ew/cases/EWHC'
                },
                { 'name': 'Family Division Decisions', 'id': 'ew/cases/EWHC/Fam' },
                { 'name': 'Family Division Decisions', 'id': 'nie/cases/NIHC/Fam' },
                { 'name': 'High Court of Ireland Decisions', 'id': 'ie/cases/IEHC' },
                {
                    'name': 'High Court of Northern Ireland Decisions',
                    'id': 'nie/cases/NIHC'
                },
                { 'name': 'Patents Court Decisions', 'id': 'ew/cases/EWHC/Patents' },
                { 'name': "Queen's Bench Division Decisions", 'id': 'ew/cases/EWHC/QB' },
                { 'name': "Queen's Bench Division Decisions", 'id': 'nie/cases/NIHC/QB' },
                {
                    'name': 'Scottish High Court of Justiciary Decisons',
                    'id': 'scot/cases/ScotHC'
                }]
            },
            {
                'hierarchy': 'Crown Courts',
                'courts': [{
                    'name': 'Crown Court for Northern Ireland Decisions',
                    'id': 'nie/cases/NICC'
                }]
            },
            {
                'hierarchy': 'County Courts',
                'courts': [{
                    'name': 'Patents County Court, Intellectual Property Enterprise Court',
                    'id': 'ew/cases/EWPCC'
                }]
            },
            {
                'hierarchy': 'Tribunals',
                'courts': [{ 'name': 'Care Standards Tribunal', 'id': 'ew/cases/EWCST' },
                { 'name': 'Competition Appeals Tribunal', 'id': 'uk/cases/CAT' },
                { 'name': 'Employment Appeal Tribunal', 'id': 'uk/cases/UKEAT' },
                {
                    'name': 'Fair Employment Tribunal Northern Ireland Decisions',
                    'id': 'nie/cases/NIFET'
                },
                {
                    'name': 'Financial Services and Markets Tribunals Decisions',
                    'id': 'uk/cases/UKFSM'
                },
                {
                    'name': 'Industrial Tribunals Northern Ireland Decisions',
                    'id': 'nie/cases/NIIT'
                },
                {
                    'name': 'Information Tribunal including the National Security Appeals Panel',
                    'id': 'uk/cases/UKIT'
                },
                { 'name': 'Lands Tribunal', 'id': 'ew/cases/EWLands' },
                { 'name': 'VAT & Duties Tribunals Decisions', 'id': 'uk/cases/UKVAT' }]
            },
            {
                'hierarchy': 'Others',
                'courts': [{
                    'name': 'Administrative Court Decisions',
                    'id': 'ew/cases/EWHC/Admin'
                },
                {
                    'name': 'Court of Justice of the European Union (including the General Court)',
                    'id': 'eu/cases/EUECJ'
                },
                { 'name': 'England and Wales Court of Protection', 'id': 'ew/cases/EWCOP' },
                { 'name': 'Europe: Case Law', 'id': 'eu/cases' },
                { 'name': 'European Court of Human Rights', 'id': 'eu/cases/ECHR' },
                { 'name': 'Exchequer Court Decisions', 'id': 'ew/cases/EWHC/Exch' },
                { 'name': 'Ireland: Case Law', 'id': 'ie/cases' },
                {
                    'name': 'Irish Competition Authority Decisions',
                    'id': 'ie/cases/IECompA'
                },
                {
                    'name': 'Irish Data Protection Commission Case Studies',
                    'id': 'ie/cases/IEDPC'
                },
                {
                    'name': "Irish Information Commissioner's Decisions",
                    'id': 'ie/cases/IEIC'
                },
                { 'name': 'Jersey Law Reports', 'id': 'je/cases/JLR' },
                { 'name': 'Jersey Unreported Judgments', 'id': 'je/cases/UR' },
                { 'name': 'Jersey: All Case Law', 'id': 'je/cases' },
                { 'name': 'Jersey: All Case Law', 'id': 'sh/cases' },
                { 'name': "King's Bench Division Decisions", 'id': 'ew/cases/EWHC/KB' },
                { 'name': "Master's Decisions", 'id': 'nie/cases/NIHC/Master' },
                { 'name': 'Mercantile Court Decisions', 'id': 'ew/cases/EWHC/Mercantile' },
                { 'name': 'Nominet UK Dispute Resolution Service', 'id': 'uk/cases/DRS' },
                {
                    'name': "Northern Ireland - Social Security and Child Support Commissioners' Decisions",
                    'id': 'nie/cases/NISSCSC'
                },
                { 'name': 'Privy Council Decisions', 'id': 'uk/cases/UKPC' },
                { 'name': 'Scotland: Case Law', 'id': 'scot/cases' },
                {
                    'name': 'Scottish Court of Session Decisions',
                    'id': 'scot/cases/ScotCS'
                },
                {
                    'name': 'Scottish Information Commissioner Decisions',
                    'id': 'scot/cases/ScotIC'
                },
                { 'name': 'Scottish Sheriff Court Decisions', 'id': 'scot/cases/ScotSC' },
                {
                    'name': 'Senior Court Costs Office Decisions',
                    'id': 'ew/cases/EWHC/Costs'
                },
                {
                    'name': "Social Security and Child Support Commissioners' Decisions",
                    'id': 'uk/cases/UKSSCSC'
                },
                {
                    'name': 'Special Commissioners of Income Tax Decisions',
                    'id': 'uk/cases/UKSPC'
                },
                {
                    'name': 'Special Immigrations Appeals Commission',
                    'id': 'uk/cases/SIAC'
                },
                {
                    'name': 'Technology and Construction Court Decisions',
                    'id': 'ew/cases/EWHC/TCC'
                }]
            }]
        },
        'New Zealand': {
            Alphabet: ['C',
                'D',
                'E',
                'F',
                'H',
                'I',
                'L',
                'M',
                'N',
                'O',
                'S'],
            courts: [{
                'hierarchy': 'Supreme Courts',
                'courts': [{ 'name': 'Supreme Court of New Zealand 2004', 'id': 'NZSC' }]
            },
            {
                'hierarchy': 'Court of Appeal',
                'courts': [{ 'name': 'Court of Appeal of New Zealand 1888', 'id': 'NZCA' },
                {
                    'name': 'Courts Martial Appeal Court of New Zealand 1972',
                    'id': 'NZCMAC'
                },
                { 'name': 'Employment Court of New Zealand 1988', 'id': 'NZEmpC' },
                { 'name': 'Labour Court of New Zealand 19871991', 'id': 'NZLabC' },
                {
                    'name': 'Maori Appellate Court of New Zealand Decisions 1948',
                    'id': 'NZMAC'
                },
                { 'name': 'Maori Land Court of New Zealand Decisions 1948', 'id': 'NZMLC' },
                { 'name': 'New Zealand Court of Appeal Reports 18611877', 'id': 'NZCARp' },
                {
                    'name': 'New Zealand Employment Relations Authority Decisions 2000',
                    'id': 'NZERA'
                },
                {
                    'name': 'New Zealand Health and Safety in Employment Decisions 1996',
                    'id': 'NZHSE'
                },
                {
                    'name': 'New Zealand Ministry of Business, Innovation and Employment  Building Determinations 1992',
                    'id': 'NZMBIEBldg'
                }]
            },
            {
                'hierarchy': 'High Courts',
                'courts': [{
                    'name': 'Environment Court of New Zealand 1996',
                    'id': 'NZEnvC'
                },
                { 'name': 'High Court of New Zealand 1847', 'id': 'NZHC' },
                {
                    'name': 'Social Security Appeal Authority of New Zealand Decisions 2003',
                    'id': 'NZSSAA'
                },
                {
                    'name': 'Student Allowance Appeal Authority of New Zealand Decisions 2004',
                    'id': 'NZSAAA'
                }]
            },
            {
                'hierarchy': 'District Courts',
                'courts': [{ 'name': 'District Court of New Zealand 1981', 'id': 'NZDC' },
                { 'name': 'Family Court of New Zealand 1997', 'id': 'NZFC' },
                {
                    'name': 'New Zealand District Licensing Committee  Auckland 2014',
                    'id': 'NZDLCAK'
                },
                {
                    'name': 'New Zealand District Licensing Committee  Hamilton 2014',
                    'id': 'NZDLCHAM'
                },
                {
                    'name': 'New Zealand District Licensing Committee  Porirua 2014',
                    'id': 'NZDLCPOR'
                },
                {
                    'name': 'New Zealand District Licensing Committee  Queenstown Lakes District Council 2015',
                    'id': 'NZDLCQLDC'
                },
                {
                    'name': 'New Zealand District Licensing Committee  Tauranga 2021',
                    'id': 'NZDLCTGA'
                },
                {
                    'name': 'New Zealand District Licensing Committee  Waipa 2016',
                    'id': 'NZDLCWP'
                },
                {
                    'name': 'New Zealand District Licensing Committee  Wellington 2014',
                    'id': 'NZDLCWN'
                },
                { 'name': 'New Zealand Youth Court 2005', 'id': 'NZYC' }]
            },
            {
                'hierarchy': 'Tribunals',
                'courts': [{
                    'name': 'New Zealand Agricultural Tribunal 19791985',
                    'id': 'NZAgrT'
                },
                {
                    'name': 'New Zealand Aircrew Industrial Tribunal 19811987',
                    'id': 'NZAIT'
                },
                { 'name': 'New Zealand Broadcasting Tribunal 19801990', 'id': 'NZBT' },
                {
                    'name': 'New Zealand Canterbury Earthquakes Insurance Tribunal 2019',
                    'id': 'NZCEIT'
                },
                {
                    'name': 'New Zealand Complaints Review Tribunal Decisions 19932007',
                    'id': 'NZCRT'
                },
                {
                    'name': 'New Zealand Copyright Tribunal Decisions 1977',
                    'id': 'NZCopyT'
                },
                {
                    'name': 'New Zealand Deportation Review Tribunal Decisions 19982010',
                    'id': 'NZDRT'
                },
                { 'name': 'New Zealand Disputes Tribunal Decisions 2006', 'id': 'NZDispT' },
                { 'name': 'New Zealand Employment Tribunal 19992003', 'id': 'NZEmpT' },
                {
                    'name': 'New Zealand Equal Opportunities Tribunal 19801993',
                    'id': 'NZEOT'
                },
                {
                    'name': 'New Zealand Health Practitioners Disciplinary Tribunal 2004',
                    'id': 'NZHPDT'
                },
                {
                    'name': 'New Zealand Human Rights Review Tribunal Decisions 2002',
                    'id': 'NZHRRT'
                },
                {
                    'name': 'New Zealand Immigration Advisers Complaints and Disciplinary Tribunal 2010',
                    'id': 'NZIACDT'
                },
                {
                    'name': 'New Zealand Immigration and Protection Tribunal Decisions 2010',
                    'id': 'NZIPT'
                },
                {
                    'name': 'New Zealand Indecent Publications Tribunal 19641996',
                    'id': 'NZIndPubT'
                },
                {
                    'name': 'New Zealand Land Valuation Tribunal Decisions 1994',
                    'id': 'NZLVT'
                },
                {
                    'name': 'New Zealand Lawyers and Conveyancers Disciplinary Tribunal Decisions 2009',
                    'id': 'NZLCDT'
                },
                { 'name': 'New Zealand Legal Aid Tribunal 2011', 'id': 'NZLAT' },
                {
                    'name': 'New Zealand Medical Practitioners Disciplinary Tribunal 19972010',
                    'id': 'NZMPDT'
                },
                {
                    'name': 'New Zealand Mental Health Review Tribunal 2009',
                    'id': 'NZMHRT'
                },
                {
                    'name': 'New Zealand Motor Vehicle Disputes Tribunal Decisions 1987',
                    'id': 'NZMVDT'
                },
                { 'name': 'New Zealand Planning Tribunal 19781996', 'id': 'NZPT' },
                {
                    'name': 'New Zealand Real Estate Agents Disciplinary Tribunal 2010',
                    'id': 'NZREADT'
                },
                { 'name': 'New Zealand Sports Tribunal 2003', 'id': 'NZST' },
                {
                    'name': 'New Zealand Teachers Disciplinary Tribunal 2005',
                    'id': 'NZTDT'
                },
                {
                    'name': "New Zealand Victims' Special Claims Tribunal 2009",
                    'id': 'NZVSCT'
                },
                {
                    'name': 'New Zealand Weathertight Homes Tribunal Decisions 2003',
                    'id': 'NZWHT'
                },
                {
                    'name': 'New Zealand Weathertight Homes Tribunal Eligibility Decisions 2008',
                    'id': 'NZWHTE'
                }]
            },
            {
                'hierarchy': 'Others',
                'courts': [{
                    'name': "Fenton's Important Judgments (New Zealand) 18661878",
                    'id': 'NZFImpJud'
                },
                {
                    'name': 'Intellectual Property Office of New Zealand  Designs 1958',
                    'id': 'NZIPODES'
                },
                {
                    'name': 'Intellectual Property Office of New Zealand  Patents 1957',
                    'id': 'NZIPOPAT'
                },
                {
                    'name': 'Intellectual Property Office of New Zealand  Plant Variety Rights 1984',
                    'id': 'NZIPOPVR'
                },
                {
                    'name': 'Intellectual Property Office of New Zealand  Trade Marks 1955',
                    'id': 'NZIPOTM'
                },
                { 'name': "Macassey's Reports New Zealand 18591872", 'id': 'NZMacasRp' },
                {
                    'name': 'New Zealand Accident Compensation Appeal Authority Decisions 1978',
                    'id': 'NZACAA'
                },
                {
                    'name': 'New Zealand Accident Compensation Appeals  ACC Appeal Decisions 1993',
                    'id': 'NZACC'
                },
                {
                    'name': 'New Zealand Accident Compensation Reports 19761984',
                    'id': 'NZACCRp'
                },
                {
                    'name': 'New Zealand Advertising Standards Authority 2001',
                    'id': 'NZASA'
                },
                {
                    'name': 'New Zealand Air Services Licensing Appeal Authority 19771983',
                    'id': 'NZASLAA'
                },
                {
                    'name': 'New Zealand Alcohol Regulatory and Licensing Authority 2013',
                    'id': 'NZARLA'
                },
                {
                    'name': 'New Zealand Appeals to the Privy Council 18512013',
                    'id': 'NZPC'
                },
                { 'name': 'New Zealand Arbitration Commission 19881991', 'id': 'NZArbCom' },
                { 'name': 'New Zealand Arbitration Court 1986', 'id': 'NZArbC' },
                {
                    'name': 'New Zealand Arbitration Court Judgments 19791986',
                    'id': 'NZArbCJud'
                },
                {
                    'name': 'New Zealand Book of Awards  Decisions 1960',
                    'id': 'NZBkAwdDec'
                },
                {
                    'name': 'New Zealand Broadcasting Standards Authority Decisions 1995',
                    'id': 'NZBSA'
                },
                { 'name': 'New Zealand Colonial Law Journal 18651875', 'id': 'NZColLawJl' },
                {
                    'name': 'New Zealand Commerce Commission Decisions 1975',
                    'id': 'NZComComm'
                },
                { 'name': 'New Zealand Compensation Court 1967', 'id': 'NZCompC' },
                { 'name': 'New Zealand Coroners Court 2007', 'id': 'NZCorC' },
                {
                    'name': 'New Zealand Customs Appeal Authority Decisions 1997',
                    'id': 'NZCAA'
                },
                { 'name': 'New Zealand Domain Name Commission 2006', 'id': 'NZDNC' },
                {
                    'name': 'New Zealand Film and Literature Board of Review 1997',
                    'id': 'NZFLBR'
                },
                { 'name': 'New Zealand Gazette Law Reports 18761953', 'id': 'NZGazLawRp' },
                {
                    'name': 'New Zealand Human Rights Commissioner Case Notes 1988',
                    'id': 'NZHRCCN'
                },
                {
                    'name': 'New Zealand Industrial Court Judgments 19741978',
                    'id': 'NZICJud'
                },
                { 'name': 'New Zealand Industrial Law Reports 19871990', 'id': 'NZILawRp' },
                { 'name': 'New Zealand Jurist Reports 18591879', 'id': 'NZJurRp' },
                { 'name': 'New Zealand Land Valuation Cases 19611993', 'id': 'NZLVCas' },
                {
                    'name': 'New Zealand Legal Aid Appeal Authority 19761993',
                    'id': 'NZLAAA'
                },
                {
                    'name': 'New Zealand Legal Aid Review Authority 19922001',
                    'id': 'NZLARA'
                },
                { 'name': 'New Zealand Legal Aid Review Panel 20012011', 'id': 'NZLARP' },
                {
                    'name': 'New Zealand Legal Complaints Review Officer Decisions 2009',
                    'id': 'NZLCRO'
                },
                {
                    'name': 'New Zealand Licensing Authority of Secondhand Dealers and Pawnbrokers Decisions 2005',
                    'id': 'NZSHD'
                },
                {
                    'name': 'New Zealand Licensing Control Commission 19701990',
                    'id': 'NZLCC'
                },
                {
                    'name': 'New Zealand Liquor Licensing Authority Decisions 19902012',
                    'id': 'NZLLA'
                },
                { 'name': 'New Zealand Lost Cases 18421882', 'id': 'NZLostC' },
                { 'name': 'New Zealand Magistrates Court 19761980', 'id': 'NZMC' },
                {
                    'name': "New Zealand Magistrates' Court Reports 18981952",
                    'id': 'NZMCRp'
                },
                {
                    'name': 'New Zealand Matrimonial Property Cases 19771982',
                    'id': 'NZMPC'
                },
                { 'name': 'New Zealand Media Council 2018', 'id': 'NZMediaC' },
                {
                    'name': 'New Zealand Motor Spirits Licensing Appeal Authority 1965',
                    'id': 'NZMSLAA'
                },
                { 'name': 'New Zealand Native Appellate Court 18941947', 'id': 'NZNAC' },
                { 'name': 'New Zealand Native Land Court 18691945', 'id': 'NZNLC' },
                {
                    'name': 'New Zealand Online Media Standards Authority 20132016',
                    'id': 'NZOMSA'
                },
                {
                    'name': 'New Zealand Overtime and Shift Work Recognition Authority 19751982',
                    'id': 'NZOSWRA'
                },
                { 'name': 'New Zealand Pharmacy Authority 19781997', 'id': 'NZPhA' },
                {
                    'name': 'New Zealand Plumbers Gasfitters and Drainlayers Board 2018',
                    'id': 'NZPGDB'
                },
                {
                    'name': 'New Zealand Police Law Reports 19101968',
                    'id': 'NZPoliceLawRp'
                },
                { 'name': 'New Zealand Press Council 19942018', 'id': 'NZPressC' },
                {
                    'name': 'New Zealand Privacy Commissioner Cases 1994',
                    'id': 'NZPrivCmr'
                },
                {
                    'name': 'New Zealand Private Security Personnel Licensing Authority 2019',
                    'id': 'NZPSPLA'
                },
                { 'name': 'New Zealand Real Estate Agents Authority 2010', 'id': 'NZREAA' },
                {
                    'name': 'New Zealand Real Estate Agents Licensing Board 19842009',
                    'id': 'NZREALB'
                },
                {
                    'name': 'New Zealand Refugee Status Appeals Authority 19912010',
                    'id': 'NZRSAA'
                },
                { 'name': 'New Zealand Removal Review Authority 19912010', 'id': 'NZRRA' },
                { 'name': 'New Zealand Residence Review Board 20062010', 'id': 'NZRRB' },
                { 'name': 'New Zealand Review Authority 2011', 'id': 'NZRA' },
                {
                    'name': 'New Zealand Taxation Review Authority Decisions 1984',
                    'id': 'NZTRA'
                },
                {
                    'name': 'New Zealand Town and Country Planning Appeal Board 19551983',
                    'id': 'NZTCPAB'
                },
                {
                    'name': 'New Zealand Trade Practices Appeal Authority 19591965',
                    'id': 'NZTPAA'
                },
                {
                    'name': 'New Zealand Trade Practices and Prices Commission 19591967',
                    'id': 'NZTPPCom'
                },
                {
                    'name': 'New Zealand Transport Charges Appeal Authority 19691981',
                    'id': 'NZTCAA'
                },
                {
                    'name': 'New Zealand Transport Licensing Appeal Authority 19681981',
                    'id': 'NZTLAA'
                },
                {
                    'name': 'New Zealand Workers Compensation Cases 19011940',
                    'id': 'NZWkrCompC'
                },
                {
                    'name': "Ollivier, Bell, & Fitzgerald's Reports New Zealand 18781880",
                    'id': 'NZOBFitzRp'
                }]
            }]
        },
        'Australia': {
            Alphabet: ['A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'K',
                'L',
                'M',
                'N',
                'O',
                'P',
                'Q',
                'R',
                'S',
                'T',
                'V',
                'W'],
            courts: [{
                'hierarchy': 'High Courts',
                'courts': [{ 'name': 'High Court of Australia 1903', 'id': 'cth/HCA' },
                {
                    'name': 'High Court of Australia Special Leave Dispositions 2008',
                    'id': 'cth/HCASL'
                },
                {
                    'name': 'High Court of Australia Transcripts 1983',
                    'id': 'cth/HCATrans'
                }]
            },
            {
                'hierarchy': 'Supreme Courts',
                'courts': [{
                    'name': "Knox's New South Wales Supreme Court Reports 18731877",
                    'id': 'nsw/NSWKnoxRp'
                },
                {
                    'name': "Legge's Supreme Court Cases (NSW) 18301863",
                    'id': 'nsw/NSWLeggeSC'
                },
                {
                    'name': 'Superior Courts of New South Wales 17881899',
                    'id': 'nsw/NSWSupC'
                },
                { 'name': 'Superior Courts of Tasmania 18241843', 'id': 'tas/TASSupC' },
                {
                    'name': 'Superior Courts of Western Australia 18401849',
                    'id': 'wa/WASupC'
                },
                {
                    'name': 'Supreme Court of New South Wales  Court of Appeal 1988',
                    'id': 'nsw/NSWCA'
                },
                {
                    'name': 'Supreme Court of New South Wales  Court of Criminal Appeal 1998',
                    'id': 'nsw/NSWCCA'
                },
                { 'name': 'Supreme Court of New South Wales 1993', 'id': 'nsw/NSWSC' },
                { 'name': 'Supreme Court of Norfolk Island 1984', 'id': 'nf/NFS' },
                {
                    'name': 'Supreme Court of Queensland  Court of Appeal 1992 ',
                    'id': 'qld/QCA'
                },
                { 'name': 'Supreme Court of Queensland 1994 ', 'id': 'qld/QSC' },
                {
                    'name': 'Supreme Court of South Australia  Court of Appeal 2021',
                    'id': 'sa/SASCA'
                },
                {
                    'name': 'Supreme Court of South Australia  Full Court 20102021',
                    'id': 'sa/SASCFC'
                },
                { 'name': 'Supreme Court of South Australia 1977 ', 'id': 'sa/SASC' },
                {
                    'name': 'Supreme Court of Tasmania  Court of Criminal Appeal 2010',
                    'id': 'tas/TASCCA'
                },
                { 'name': 'Supreme Court of Tasmania  Full Court 2010', 'id': 'tas/TASFC' },
                { 'name': 'Supreme Court of Tasmania 1985', 'id': 'tas/TASSC' },
                {
                    'name': 'Supreme Court of Victoria  Court of Appeal 1998',
                    'id': 'vic/VSCA'
                },
                {
                    'name': 'Supreme Court of Victoria (Unreported Judgments) 19531998',
                    'id': 'vic/VicSC'
                },
                { 'name': 'Supreme Court of Victoria 1994 ', 'id': 'vic/VSC' },
                {
                    'name': 'Supreme Court of Western Australia  Court of Appeal 1998',
                    'id': 'wa/WASCA'
                },
                { 'name': 'Supreme Court of Western Australia 1964', 'id': 'wa/WASC' },
                {
                    'name': 'Supreme Court of the Australian Capital Territory  Court of Appeal 2002',
                    'id': 'act/ACTCA'
                },
                {
                    'name': 'Supreme Court of the Australian Capital Territory (Full Court) 2014',
                    'id': 'act/ACTSCFC'
                },
                {
                    'name': 'Supreme Court of the Australian Capital Territory 1986',
                    'id': 'act/ACTSC'
                },
                {
                    'name': 'Supreme Court of the Northern Territory  Court of Appeal 1986',
                    'id': 'nt/NTCA'
                },
                {
                    'name': 'Supreme Court of the Northern Territory  Court of Criminal Appeal 1986',
                    'id': 'nt/NTCCA'
                },
                {
                    'name': 'Supreme Court of the Northern Territory  Full Court 1986',
                    'id': 'nt/NTSCFC'
                },
                {
                    'name': 'Supreme Court of the Northern Territory 1986',
                    'id': 'nt/NTSC'
                }]
            },
            {
                'hierarchy': 'Federal Courts',
                'courts': [{
                    'name': 'Federal Circuit Court of Australia 2013',
                    'id': 'cth/FCCA'
                },
                {
                    'name': 'Federal Circuit and Family Court of Australia  Division 2 General Federal Law 2021',
                    'id': 'cth/FedCFamC2G'
                },
                {
                    'name': 'Federal Court of Australia  Full Court 2002',
                    'id': 'cth/FCAFC'
                },
                { 'name': 'Federal Court of Australia 1977', 'id': 'cth/FCA' },
                {
                    'name': 'Federal Privacy Commissioner of Australia Case Notes 19892011',
                    'id': 'cth/PrivCmrA'
                },
                {
                    'name': 'Federal Privacy Commissioner of Australia Complaint Determinations 19932004',
                    'id': 'cth/PrivCmrACD'
                }]
            },
            {
                'hierarchy': 'Family Courts',
                'courts': [{
                    'name': 'Family Court of Australia  Full Court 2008',
                    'id': 'cth/FamCAFC'
                },
                { 'name': 'Family Court of Australia 1976', 'id': 'cth/FamCA' },
                {
                    'name': 'Family Court of Western Australia  Magistrates Decisions 2008',
                    'id': 'wa/FCWAM'
                },
                { 'name': 'Family Court of Western Australia 2004', 'id': 'wa/FCWA' },
                {
                    'name': 'Federal Circuit and Family Court of Australia  Division 1 Appellate Jurisdiction 2021',
                    'id': 'cth/FedCFamC1A'
                },
                {
                    'name': 'Federal Circuit and Family Court of Australia  Division 1 First Instance 2021',
                    'id': 'cth/FedCFamC1F'
                },
                {
                    'name': 'Federal Circuit and Family Court of Australia  Division 2 Family Law 2021',
                    'id': 'cth/FedCFamC2F'
                }]
            },
            {
                'hierarchy': 'Intermediate Courts ( County / District Courts)',
                'courts': [{ 'name': 'County Court of Victoria 1993 ', 'id': 'vic/VCC' },
                { 'name': 'District Court of New South Wales 1992', 'id': 'nsw/NSWDC' },
                { 'name': 'District Court of South Australia 1992 ', 'id': 'sa/SADC' },
                { 'name': 'District Court of Western Australia 1999', 'id': 'wa/WADC' },
                { 'name': 'Queensland District Court 1998', 'id': 'qld/QDC' }]
            },
            {
                'hierarchy': 'Federal Magistrates Courts',
                'courts': [{
                    'name': 'Federal Magistrates Court of Australia  Family Law 20002013',
                    'id': 'cth/FMCAfam'
                },
                {
                    'name': 'Federal Magistrates Court of Australia 20002013',
                    'id': 'cth/FMCA'
                }]
            },
            {
                'hierarchy': 'Lower Courts ( Local / Magistrates Courts)',
                'courts': [{
                    'name': 'ACT Civil and Administrative Tribunal 2009',
                    'id': 'act/ACAT'
                },
                { 'name': 'ACT Health Professions Tribunal 20062009', 'id': 'act/ACTHPT' },
                {
                    'name': 'ACT Ombudsman  Freedom of Information 2018',
                    'id': 'act/ACTOFOI'
                },
                { 'name': 'Administrative Appeals Tribunal 1976', 'id': 'cth/AATA' },
                {
                    'name': 'Administrative Appeals Tribunal Reports (Victoria) 19861998',
                    'id': 'vic/VicAATRp'
                },
                {
                    'name': 'Administrative Appeals Tribunal of the ACT 19932009',
                    'id': 'act/ACTAAT'
                },
                {
                    'name': 'Administrative Decisions Tribunal Appeal Panel of New South Wales 19992013',
                    'id': 'nsw/NSWADTAP'
                },
                {
                    'name': 'Administrative Decisions Tribunal of New South Wales 19992013',
                    'id': 'nsw/NSWADT'
                },
                {
                    'name': 'AntiDiscrimination Tribunal Queensland 19922009',
                    'id': 'qld/QADT'
                },
                {
                    'name': 'AntiDiscrimination Tribunal of Tasmania 2000',
                    'id': 'tas/TASADT'
                },
                { 'name': 'Argus Law Reports 18951950', 'id': 'cth/ArgusLawRp' },
                {
                    'name': 'Argus Law Reports, Current Notes 18961950',
                    'id': 'cth/ArgusLawRpCN'
                },
                { 'name': 'Australia Domain Name 2003', 'id': 'cth/AUDND' },
                {
                    'name': 'Australian Coal Industry Tribunal 19401995',
                    'id': 'cth/ACIndT'
                },
                { 'name': 'Australian Competition Tribunal 1997', 'id': 'cth/ACompT' },
                { 'name': 'Australian Designs Office 1982', 'id': 'cth/ADO' },
                {
                    'name': 'Australian Industrial Relations Commission  Full Bench 20072009',
                    'id': 'cth/AIRCFB'
                },
                {
                    'name': 'Australian Industrial Relations Commission 19832009',
                    'id': 'cth/AIRC'
                },
                { 'name': 'Australian Information Commissioner 2011', 'id': 'cth/AICmr' },
                {
                    'name': 'Australian Information Commissioner Case Notes 2011',
                    'id': 'cth/AICmrCN'
                },
                { 'name': 'Australian Patent Office 1981', 'id': 'cth/APO' },
                {
                    'name': "Australian Plant Breeder's Rights Office Decisions 2016",
                    'id': 'cth/APBRO'
                },
                { 'name': 'Australian Takeovers Panel 2000', 'id': 'cth/ATP' },
                { 'name': 'Australian Trade Marks Office 1991', 'id': 'cth/ATMO' },
                {
                    'name': 'Australian Trade Marks Office Geographical Indication 2008',
                    'id': 'cth/ATMOGI'
                },
                { 'name': 'Building Appeals Board of Victoria 2018', 'id': 'vic/VBAB' },
                {
                    'name': "Chief Industrial Magistrate's Court of New South Wales 1999",
                    'id': 'nsw/NSWCIMC'
                },
                {
                    'name': 'Childrens Court of Queensland (Magistrates) 2007',
                    'id': 'qld/QChCM'
                },
                { 'name': 'Childrens Court of Queensland 2004', 'id': 'qld/QChC' },
                {
                    'name': 'Chiropractic and Osteopathy Board of South Australia  Court Prosecutions 1998',
                    'id': 'sa/SACHOSBCP'
                },
                {
                    'name': 'Chiropractic and Osteopathy Board of South Australia  Disciplinary Decisions 1992',
                    'id': 'sa/SACHOSB'
                },
                {
                    'name': 'Chiropractors Tribunal of New South Wales 2008',
                    'id': 'nsw/NSWCHT'
                },
                {
                    'name': 'Civil and Administrative Tribunal of New South Wales  Administrative and Equal Opportunity Division 2014',
                    'id': 'nsw/NSWCATAD'
                },
                {
                    'name': 'Civil and Administrative Tribunal of New South Wales  Appeal Panel 2014',
                    'id': 'nsw/NSWCATAP'
                },
                {
                    'name': 'Civil and Administrative Tribunal of New South Wales  Consumer and Commercial Division 2014',
                    'id': 'nsw/NSWCATCD'
                },
                {
                    'name': 'Civil and Administrative Tribunal of New South Wales  Guardianship Division 2014',
                    'id': 'nsw/NSWCATGD'
                },
                {
                    'name': 'Civil and Administrative Tribunal of New South Wales  Occupational Division 2014',
                    'id': 'nsw/NSWCATOD'
                },
                {
                    'name': 'Commonwealth Arbitration Reports 19051993',
                    'id': 'cth/CthArbRp'
                },
                {
                    'name': 'Community Services Appeals Tribunal of New South Wales 1998',
                    'id': 'nsw/csat'
                },
                {
                    'name': 'Compensation Court of New South Wales 19852003',
                    'id': 'nsw/NSWCC'
                },
                {
                    'name': 'Consumer, Trader and Tenancy Tribunal of New South Wales 20022013',
                    'id': 'nsw/NSWCTTT'
                },
                { 'name': 'Copyright Tribunal 1982', 'id': 'cth/ACopyT' },
                { 'name': 'Coroners Court of New South Wales 1979', 'id': 'nsw/NSWCorC' },
                { 'name': 'Coroners Court of Queensland 2004', 'id': 'qld/QldCorC' },
                { 'name': 'Coroners Court of South Australia 2000', 'id': 'sa/SACorC' },
                { 'name': 'Coroners Court of Victoria 2002', 'id': 'vic/VicCorC' },
                { 'name': 'Coroners Court of Western Australia 2012', 'id': 'wa/WACorC' },
                {
                    'name': 'Coroners Court of the Australian Capital Territory 2013',
                    'id': 'act/ACTCD'
                },
                {
                    'name': 'Criminal Injuries Compensation Assessor of Western Australia 2004 ',
                    'id': 'wa/WACIC'
                },
                {
                    'name': 'Defence Force Discipline Appeal Tribunal 1999',
                    'id': 'cth/ADFDAT'
                },
                { 'name': 'Dental Board of South Australia 2006', 'id': 'sa/SADB' },
                { 'name': 'Dental Practice Board of Victoria 2000', 'id': 'vic/VDPB' },
                { 'name': 'Dental Tribunal of New South Wales 2009', 'id': 'nsw/NSWDT' },
                {
                    'name': 'Discrimination Tribunal of the ACT 19982009',
                    'id': 'act/ACTDT'
                },
                { 'name': 'Drug Court of New South Wales 1999', 'id': 'nsw/NSWDRGC' },
                {
                    'name': 'Dust Diseases Tribunal of New South Wales 1989',
                    'id': 'nsw/NSWDDT'
                },
                {
                    'name': 'Environment Resources and Development Court of South Australia 1996 ',
                    'id': 'sa/SAERDC'
                },
                {
                    'name': 'Equal Opportunity Tribunal of South Australia 2004',
                    'id': 'sa/SAEOT'
                },
                {
                    'name': 'Fair Trading Tribunal of New South Wales 19992001',
                    'id': 'nsw/NSWFTT'
                },
                {
                    'name': 'Fair Work Australia  Enterprise Agreement 20092012',
                    'id': 'cth/FWAA'
                },
                { 'name': 'Fair Work Australia  Full Bench 20092012', 'id': 'cth/FWAFB' },
                { 'name': 'Fair Work Australia 20092012', 'id': 'cth/FWA' },
                {
                    'name': 'Fair Work Commission  Enterprise Agreement 2013',
                    'id': 'cth/FWCA'
                },
                { 'name': 'Fair Work Commission  Full Bench 2013', 'id': 'cth/FWCFB' },
                {
                    'name': 'Fair Work Commission  General Manager and Delegates 2013',
                    'id': 'cth/FWCD'
                },
                { 'name': 'Fair Work Commission 2013', 'id': 'cth/FWC' },
                {
                    'name': 'Forest Practices Tribunal of Tasmania 1994',
                    'id': 'tas/TASFPT'
                },
                {
                    'name': 'Guardianship Tribunal of New South Wales 19962013',
                    'id': 'nsw/NSWGT'
                },
                {
                    'name': 'Guardianship and Administration Board of Western Australia 20042005',
                    'id': 'wa/WAGAB'
                },
                {
                    'name': 'Health Practitioners Tribunal of South Australia 2010',
                    'id': 'sa/SAHPT'
                },
                { 'name': 'Heritage Council of Victoria 2011', 'id': 'vic/VHerCl' },
                {
                    'name': 'Human Rights and Equal Opportunity Commission 19852001',
                    'id': 'cth/HREOCA'
                },
                { 'name': 'Immigration Review Tribunal 19901999', 'id': 'cth/IRTA' },
                { 'name': 'Industrial Court of New South Wales 2014', 'id': 'nsw/NSWIC' },
                { 'name': 'Industrial Court of Queensland 2014', 'id': 'qld/ICQ' },
                {
                    'name': 'Industrial Court of the Australian Capital Territory 2014',
                    'id': 'act/ACTIC'
                },
                {
                    'name': 'Industrial Relations Commission of New South Wales 1985',
                    'id': 'nsw/NSWIRComm'
                },
                {
                    'name': 'Industrial Relations Court of Australia 19942002',
                    'id': 'cth/IRCA'
                },
                {
                    'name': 'Insurance Ombudsman Service Limited Privacy Compliance Committee Complaint Determinations 20042005',
                    'id': 'cth/IOSPCC'
                },
                {
                    'name': 'Intellectual Property Office of Singapore 1999',
                    'id': 'SGIPOS'
                },
                { 'name': 'Land Appeal Court of Queensland 1985', 'id': 'qld/QLAC' },
                { 'name': 'Land Court of Queensland 1989', 'id': 'qld/QLC' },
                {
                    'name': 'Land and Environment Court of New South Wales 1987',
                    'id': 'nsw/NSWLEC'
                },
                {
                    'name': 'Land and Resources Tribunal of Queensland 20002007',
                    'id': 'qld/QLRT'
                },
                { 'name': 'Law Reports (New South Wales) 18561900', 'id': 'nsw/NSWLawRp' },
                {
                    'name': 'Legal Profession Disciplinary Tribunal of Tasmania 2018',
                    'id': 'tas/TASLPDT'
                },
                { 'name': 'Licensing Court of South Australia 1994 ', 'id': 'sa/SALC' },
                {
                    'name': 'Liquor and Gambling Commissioner of South Australia 2002',
                    'id': 'sa/SALGCmr'
                },
                { 'name': 'Local Court of the Northern Territory 2016', 'id': 'nt/NTLC' },
                { 'name': 'Magistrates Court of Queensland 2006', 'id': 'qld/QMC' },
                { 'name': 'Magistrates Court of Tasmania 2002', 'id': 'tas/TASMC' },
                {
                    'name': 'Magistrates Court of the Australian Capital Territory 2012',
                    'id': 'act/ACTMC'
                },
                {
                    'name': 'Magistrates Court of the Northern Territory 2002',
                    'id': 'nt/NTMC'
                },
                { 'name': "Magistrates' Court of Victoria 2006", 'id': 'vic/VMC' },
                { 'name': 'Medical Board of South Australia 2005', 'id': 'sa/SAMB' },
                { 'name': 'Medical Board of Western Australia 2010', 'id': 'wa/WAMB' },
                {
                    'name': 'Medical Board of the ACT  Professional Standards Panel Outcomes 2005',
                    'id': 'act/ACTMBPSP'
                },
                {
                    'name': 'Medical Practitioners Board of Victoria  Formal Hearings 2001',
                    'id': 'vic/VMPB'
                },
                {
                    'name': 'Medical Practitioners Board of Victoria  Professional Standards Panel Hearings 2009',
                    'id': 'vic/VMPBPSP'
                },
                { 'name': 'Migration Review Tribunal 19992015', 'id': 'cth/MRTA' },
                { 'name': 'National Native Title Tribunal 1994', 'id': 'cth/NNTTA' },
                {
                    'name': 'New South Wales Industrial Gazettes 19021950',
                    'id': 'nsw/NSWIndGaz'
                },
                {
                    'name': 'New South Wales Medical Professional Standards Committee 2008',
                    'id': 'nsw/NSWMPSC'
                },
                { 'name': 'New South Wales Medical Tribunal 20102013', 'id': 'nsw/NSWMT' },
                {
                    'name': 'New South Wales Mental Health Review Tribunal 2013',
                    'id': 'nsw/NSWMHRT'
                },
                {
                    'name': 'New South Wales Privacy Commissioner Cases 1997',
                    'id': 'nsw/NSWPrivCmr'
                },
                {
                    'name': 'New South Wales State Insurance Regulatory Authority Dispute Resolution Service 2020',
                    'id': 'nsw/NSWSIRADRS'
                },
                {
                    'name': 'Northern Territory AntiDiscrimination Commission 1996',
                    'id': 'nt/NTADComm'
                },
                {
                    'name': 'Northern Territory Civil and Administrative Tribunal 2015',
                    'id': 'nt/NTCAT'
                },
                {
                    'name': 'Northern Territory Consumer Affairs Residential Building Disputes 2016',
                    'id': 'nt/NTCARB'
                },
                { 'name': 'Northern Territory Coroners Court 1979', 'id': 'nt/NTCorC' },
                {
                    'name': 'Northern Territory Health Professional Review Tribunal 2010',
                    'id': 'nt/NTHPRT'
                },
                { 'name': 'Northern Territory Judgments 19181976', 'id': 'nt/NTJud' },
                {
                    'name': 'Northern Territory Mental Health Review Tribunal 2015',
                    'id': 'nt/NTMHRT'
                },
                {
                    'name': 'Northern Territory Residential Tenancies Commissioner 2006',
                    'id': 'nt/NTRTCmr'
                },
                {
                    'name': 'Nursing and Midwifery Professional Standards Committee of New South Wales 2011',
                    'id': 'nsw/NSWNMPSC'
                },
                {
                    'name': 'Nursing and Midwifery Tribunal of New South Wales 2007',
                    'id': 'nsw/NSWNMT'
                },
                {
                    'name': 'Office of the Victorian Privacy Commissioner Case Notes 2003',
                    'id': 'vic/VPrivCmr'
                },
                {
                    'name': 'Optometry Tribunal of New South Wales 2012',
                    'id': 'nsw/NSWOPT'
                },
                {
                    'name': 'Osteopathy Tribunal of New South Wales 2010',
                    'id': 'nsw/NSWOST'
                },
                {
                    'name': "Pelham's South Australian Reports 18651866",
                    'id': 'sa/SAPelhamRp'
                },
                {
                    'name': 'Personal Injury Commission of New South Wales  Medical Appeal Panel and Review Panel 2021',
                    'id': 'nsw/NSWPICMP'
                },
                {
                    'name': 'Personal Injury Commission of New South Wales  Merit Review 2021',
                    'id': 'nsw/NSWPICMR'
                },
                {
                    'name': 'Personal Injury Commission of New South Wales  Merit Review Panel 2021',
                    'id': 'nsw/NSWPICMRP'
                },
                {
                    'name': 'Personal Injury Commission of New South Wales  Presidential 2021',
                    'id': 'nsw/NSWPICPD'
                },
                {
                    'name': 'Personal Injury Commission of New South Wales 2021',
                    'id': 'nsw/NSWPIC'
                },
                { 'name': 'Pharmacy Board of New South Wales 19902010', 'id': 'nsw/NSWPB' },
                { 'name': 'Pharmacy Board of South Australia 2008', 'id': 'sa/SAPHB' },
                { 'name': 'Pharmacy Tribunal of New South Wales 2009', 'id': 'nsw/NSWPHT' },
                {
                    'name': 'Physiotherapists Registration Board of Victoria 2008',
                    'id': 'vic/VPYRB'
                },
                {
                    'name': 'Physiotherapists Tribunal of New South Wales 2007',
                    'id': 'nsw/NSWPYT'
                },
                {
                    'name': 'Planning Appeals Board Reports (Victoria) 19791987',
                    'id': 'vic/VicPABRp'
                },
                { 'name': 'Planning Panels Victoria 1988', 'id': 'vic/PPV' },
                { 'name': 'Podiatry Board of South Australia 2007', 'id': 'sa/SAPDB' },
                { 'name': 'Podiatry Tribunal of New South Wales 2010', 'id': 'nsw/NSWPDT' },
                { 'name': 'Privy Council Appeals 19031980', 'id': 'cth/UKPCHCA' },
                {
                    'name': 'Psychologists Registration Board of Victoria 1999',
                    'id': 'vic/VPSRB'
                },
                {
                    'name': 'Psychologists Tribunal of New South Wales 2007',
                    'id': 'nsw/NSWPST'
                },
                {
                    'name': 'Queensland Body Corporate and Community Management Commissioner  Adjudicators Orders 2000',
                    'id': 'qld/QBCCMCmr'
                },
                { 'name': 'Queensland Building Tribunal 19942003', 'id': 'qld/QBT' },
                {
                    'name': 'Queensland Children Services Tribunal 20032009',
                    'id': 'qld/QCST'
                },
                {
                    'name': 'Queensland Civil and Administrative Tribunal 2009',
                    'id': 'qld/QCAT'
                },
                {
                    'name': 'Queensland Civil and Administrative Tribunal Appeals 2010',
                    'id': 'qld/QCATA'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Architects List 20042009',
                    'id': 'qld/QCCTA'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Body Corporate and Community Management List 20072009',
                    'id': 'qld/QCCTBCCM'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Building List 20032009',
                    'id': 'qld/QCCTB'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Engineers List 20032009',
                    'id': 'qld/QCCTE'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Gaming List 20042009',
                    'id': 'qld/QCCTG'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Liquor List 20032009',
                    'id': 'qld/QCCTL'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Manufactured Homes List 20042009',
                    'id': 'qld/QCCTMH'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Plumbers and Drainers List 20042009',
                    'id': 'qld/QCCTPD'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Property Agents and Motor Dealers List 20032009',
                    'id': 'qld/QCCTPAMD'
                },
                {
                    'name': 'Queensland Commercial and Consumer Tribunal  Retirement Villages List 20032009',
                    'id': 'qld/QCCTRV'
                },
                {
                    'name': 'Queensland Guardianship and Administration Tribunal 20002009',
                    'id': 'qld/QGAAT'
                },
                { 'name': 'Queensland Industrial Court 19992009', 'id': 'qld/QIC' },
                {
                    'name': 'Queensland Industrial Gazettes 19151950',
                    'id': 'qld/QldIndGaz'
                },
                {
                    'name': 'Queensland Industrial Relations Commission 19992014',
                    'id': 'qld/QIRComm'
                },
                {
                    'name': 'Queensland Industrial Relations Commission 2014',
                    'id': 'qld/QIRC'
                },
                { 'name': 'Queensland Information Commissioner 1993', 'id': 'qld/QICmr' },
                { 'name': 'Queensland Legal Practice Tribunal 20042009', 'id': 'qld/QLPT' },
                { 'name': 'Queensland Liquor Appeals Tribunal 19922003', 'id': 'qld/QLAT' },
                { 'name': 'Queensland Mental Health Court 2002', 'id': 'qld/QMHC' },
                { 'name': 'Queensland Mining Wardens 19932000', 'id': 'qld/QMW' },
                { 'name': 'Queensland Nursing Tribunal 2004', 'id': 'qld/QNT' },
                {
                    'name': 'Queensland Planning and Environment Court 1999',
                    'id': 'qld/QPEC'
                },
                {
                    'name': 'Queensland Property Agents and Motor Dealers Tribunal 20022003',
                    'id': 'qld/QPAMDT'
                },
                {
                    'name': 'Queensland Racing Appeals Authority 20022003',
                    'id': 'qld/QRAA'
                },
                {
                    'name': 'Queensland Retirement Villages Tribunal 20012002',
                    'id': 'qld/QRVT'
                },
                {
                    'name': 'Racing Appeals Tribunal of Queensland 20032009',
                    'id': 'qld/QRAT'
                },
                { 'name': 'Refugee Review Tribunal 19932015', 'id': 'cth/RRTA' },
                {
                    'name': 'Residential Tenancies Tribunal of the ACT 19982009',
                    'id': 'act/ACTRTT'
                },
                {
                    'name': 'Residential Tribunal of New South Wales 19872002',
                    'id': 'nsw/NSWR'
                },
                {
                    'name': 'Resource Management and Planning Appeal Tribunal of Tasmania 1994',
                    'id': 'tas/TASRMPAT'
                },
                {
                    'name': 'Resource Planning and Development Commission of Tasmania 20042009',
                    'id': 'tas/TASRPDComm'
                },
                {
                    'name': 'Social Security Appeals Tribunal of Australia  Review of Child Support Agency 20072015',
                    'id': 'cth/SSATACSA'
                },
                {
                    'name': 'South Australian Civil and Administrative Tribunal 2015',
                    'id': 'sa/SACAT'
                },
                {
                    'name': 'South Australian Dental Professional Conduct Tribunal 1998',
                    'id': 'sa/SADPCT'
                },
                { 'name': 'South Australian Employment Tribunal 2015', 'id': 'sa/SAET' },
                {
                    'name': 'South Australian Industrial Relations Commission 1991',
                    'id': 'sa/SAIRComm'
                },
                {
                    'name': 'South Australian Industrial Relations Court 1992 ',
                    'id': 'sa/SAIRC'
                },
                {
                    'name': 'South Australian Industrial Reports 19161945',
                    'id': 'sa/SAIndRp'
                },
                { 'name': 'South Australian Law Reports 18631920', 'id': 'sa/SALawRp' },
                {
                    'name': 'South Australian Ombudsman FOI Determinations 2011',
                    'id': 'sa/SAOmbFOI'
                },
                {
                    'name': 'South Australian Psychological Board  Court Prosecutions 1992',
                    'id': 'sa/SAPSBCP'
                },
                {
                    'name': 'South Australian Psychological Board  Disciplinary Decisions 1991',
                    'id': 'sa/SAPSB'
                },
                {
                    'name': 'South Australian Residential Tenancies Tribunal  Residential Parks 2008',
                    'id': 'sa/SARTTRP'
                },
                {
                    'name': 'South Australian Residential Tenancies Tribunal 1996',
                    'id': 'sa/SARTT'
                },
                {
                    'name': 'South Australian Return to Work Premium Review 2019',
                    'id': 'sa/SARTWPR'
                },
                {
                    'name': 'South Australian Return to Work Premium Review Panel 2015',
                    'id': 'sa/SARTWPRP'
                },
                { 'name': 'South Australian State Reports 19201950', 'id': 'sa/SAStRp' },
                { 'name': 'South Australian Wardens Court 1994', 'id': 'sa/SAWC' },
                {
                    'name': 'South Australian WorkCover Levy Review Panel 20002012',
                    'id': 'sa/SAWLRP'
                },
                {
                    'name': 'South Australian WorkCover Premium Review Panel 2012',
                    'id': 'sa/SAWPRP'
                },
                {
                    'name': 'South Australian Workers Compensation Appeal Tribunal 1991',
                    'id': 'sa/SAWCAT'
                },
                {
                    'name': 'South Australian Workers Compensation Review Panel 19872000',
                    'id': 'sa/SAWCRP'
                },
                {
                    'name': 'South Australian Workers Compensation Tribunal 1996',
                    'id': 'sa/SAWCT'
                },
                {
                    'name': 'State Administrative Tribunal of Western Australia 2005',
                    'id': 'wa/WASAT'
                },
                { 'name': 'State Reports (New South Wales) 19011950', 'id': 'nsw/NSWStRp' },
                {
                    'name': 'Strata Schemes Board of New South Wales 19972001',
                    'id': 'nsw/NSWSSB'
                },
                {
                    'name': 'Strata Titles Referee of Western Australia 2004',
                    'id': 'wa/WASTR'
                },
                {
                    'name': 'Superannuation Complaints Tribunal of Australia 1995',
                    'id': 'cth/SCTA'
                },
                {
                    'name': 'Tasmanian Civil and Administrative Tribunal 2021',
                    'id': 'tas/TASCAT'
                },
                { 'name': 'Tasmanian Coroners Court 2001', 'id': 'tas/TasCorC' },
                {
                    'name': 'Tasmanian Guardianship and Administration Board 1998',
                    'id': 'tas/TASGAB'
                },
                {
                    'name': 'Tasmanian Health Practitioners Tribunal 2011',
                    'id': 'tas/TASHPT'
                },
                { 'name': 'Tasmanian Law Reports 18971940', 'id': 'tas/TASLawRp' },
                {
                    'name': 'Tasmanian Motor Accidents Compensation Tribunal 1976',
                    'id': 'tas/TASMACT'
                },
                { 'name': 'Tasmanian Planning Commission 2009', 'id': 'tas/TASPComm' },
                { 'name': 'Tasmanian Reports 19581991', 'id': 'tas/TASRp' },
                { 'name': 'Tasmanian State Reports 19411978', 'id': 'tas/TASStRp' },
                { 'name': 'Tenancy Tribunal of the ACT 19961998', 'id': 'act/ACTTT' },
                {
                    'name': 'The Victorian Law Times and Legal Observer 18561857',
                    'id': 'vic/VicLawTLegO'
                },
                {
                    'name': 'Town Planning Appeal Tribunal of Western Australia 20032004',
                    'id': 'wa/WATPAT'
                },
                {
                    'name': 'Victorian AntiDiscrimination Tribunal 19951998',
                    'id': 'vic/VADT'
                },
                {
                    'name': 'Victorian Civil and Administrative Tribunal 1998',
                    'id': 'vic/VCAT'
                },
                {
                    'name': 'Victorian Civil and Administrative Tribunal/Administrative Appeals Tribunal of Victoria 19851999',
                    'id': 'vic/VICCAT'
                },
                {
                    'name': 'Victorian Commission for Gambling and Liquor Regulation 2016',
                    'id': 'vic/VCGLR'
                },
                {
                    'name': 'Victorian Domestic Building Tribunal 19961998',
                    'id': 'vic/VDBT'
                },
                { 'name': 'Victorian Information Commissioner 2019', 'id': 'vic/VICmr' },
                { 'name': 'Victorian Law Reports 18741956', 'id': 'vic/VicLawRp' },
                {
                    'name': 'Victorian Legal Profession Tribunal 19972005',
                    'id': 'vic/VLPT'
                },
                { 'name': 'Victorian Legal Services Commissioner 2016', 'id': 'vic/VLSC' },
                { 'name': 'Victorian Mental Health Review Board 1987', 'id': 'vic/VMHRB' },
                { 'name': 'Victorian Mental Health Tribunal 2014', 'id': 'vic/VMHT' },
                { 'name': 'Victorian Planning Reports 19982013', 'id': 'vic/VicPRp' },
                { 'name': 'Victorian Racing Appeals Tribunal 19842009', 'id': 'vic/VRAT' },
                { 'name': 'Victorian Reports 19531996', 'id': 'vic/VicRp' },
                {
                    'name': "Webb, A'Beckett and William's Reports (Victoria) 18701872",
                    'id': 'vic/VicWABWRp'
                },
                {
                    'name': 'Western Australian Building Disputes Tribunal 20002011',
                    'id': 'wa/WABDT'
                },
                {
                    'name': 'Western Australian Industrial Relations Commission 19912016',
                    'id': 'wa/WAIRComm'
                },
                {
                    'name': 'Western Australian Industrial Relations Commission 2017',
                    'id': 'wa/WAIRC'
                },
                {
                    'name': 'Western Australian Information Commissioner 1994',
                    'id': 'wa/WAICmr'
                },
                { 'name': 'Western Australian Law Reports 18981958', 'id': 'wa/WALawRp' },
                { 'name': 'Western Australian Warden of Mines 1979', 'id': 'wa/WAWM' },
                {
                    'name': 'Workers Compensation Commission of New South Wales  Presidential 2002',
                    'id': 'nsw/NSWWCCPD'
                },
                {
                    'name': 'Workers Rehabilitation and Compensation Tribunal of Tasmania 2004',
                    'id': 'tas/TASWRCT'
                },
                {
                    'name': "Wyatt and Webb's Reports (Victoria) 18611863",
                    'id': 'vic/VicWWRp'
                },
                {
                    'name': "Wyatt, Webb and A'Beckett's Reports (Victoria) 18641869",
                    'id': 'vic/VicWWABRp'
                }]
            }]
        },
        'United States of America': {
            Alphabet: ['A',
                'B',
                'C',
                'F',
                'J',
                'M',
                'N',
                'O',
                'S',
                'U',
                'V'],
            courts: [{
                'hierarchy': 'Supreme Courts',
                'courts': [{ 'name': 'FEDERAL APPELLATE - Supreme Court', 'id': 'scotus' },
                { 'name': 'State Courts - Alaska Supreme Court', 'id': 'alaska' },
                {
                    'name': 'State Courts - Appellate Division of the Supreme Court of New York',
                    'id': 'nyappdiv'
                },
                {
                    'name': 'State Courts - Appellate Terms of the Supreme Court of New York',
                    'id': 'nyappterm'
                },
                { 'name': 'State Courts - Arizona Supreme Court', 'id': 'ariz' },
                { 'name': 'State Courts - California Supreme Court', 'id': 'cal' },
                { 'name': 'State Courts - Hawaii Supreme Court', 'id': 'haw' },
                { 'name': 'State Courts - Idaho Supreme Court', 'id': 'idaho' },
                { 'name': 'State Courts - Illinois Supreme Court', 'id': 'ill' },
                { 'name': 'State Courts - Indiana Supreme Court', 'id': 'ind' },
                { 'name': 'State Courts - Kentucky Supreme Court', 'id': 'ky' },
                {
                    'name': 'State Courts - Massachusetts Supreme Judicial Court',
                    'id': 'mass'
                },
                { 'name': 'State Courts - Michigan Supreme Court', 'id': 'mich' },
                { 'name': 'State Courts - Mississippi Supreme Court', 'id': 'miss' },
                { 'name': 'State Courts - Montana Supreme Court', 'id': 'mont' },
                { 'name': 'State Courts - Nebraska Supreme Court', 'id': 'neb' },
                { 'name': 'State Courts - Nevada Supreme Court', 'id': 'nev' },
                { 'name': 'State Courts - New Mexico Supreme Court', 'id': 'nm' },
                { 'name': 'State Courts - New York Supreme Court', 'id': 'nysupct' },
                { 'name': 'State Courts - North Dakota Supreme Court', 'id': 'nd' },
                { 'name': 'State Courts - Ohio Supreme Court', 'id': 'ohio' },
                { 'name': 'State Courts - Oregon Supreme Court', 'id': 'or' },
                { 'name': 'State Courts - South Dakota Supreme Court', 'id': 'sd' },
                { 'name': 'State Courts - Supreme Court of Alabama', 'id': 'ala' },
                { 'name': 'State Courts - Supreme Court of Arkansas', 'id': 'ark' },
                { 'name': 'State Courts - Supreme Court of Colorado', 'id': 'colo' },
                { 'name': 'State Courts - Supreme Court of Connecticut', 'id': 'conn' },
                { 'name': 'State Courts - Supreme Court of Delaware', 'id': 'del' },
                { 'name': 'State Courts - Supreme Court of Florida', 'id': 'fla' },
                { 'name': 'State Courts - Supreme Court of Georgia', 'id': 'ga' },
                { 'name': 'State Courts - Supreme Court of Iowa', 'id': 'iowa' },
                { 'name': 'State Courts - Supreme Court of Kansas', 'id': 'kan' },
                { 'name': 'State Courts - Supreme Court of Louisiana', 'id': 'la' },
                { 'name': 'State Courts - Supreme Court of Minnesota', 'id': 'minn' },
                { 'name': 'State Courts - Supreme Court of Missouri', 'id': 'mo' },
                { 'name': 'State Courts - Supreme Court of New Hampshire', 'id': 'nh' },
                { 'name': 'State Courts - Supreme Court of New Jersey', 'id': 'nj' },
                { 'name': 'State Courts - Supreme Court of North Carolina', 'id': 'nc' },
                { 'name': 'State Courts - Supreme Court of Oklahoma', 'id': 'okla' },
                { 'name': 'State Courts - Supreme Court of Pennsylvania', 'id': 'pa' },
                { 'name': 'State Courts - Supreme Court of Rhode Island', 'id': 'ri' },
                { 'name': 'State Courts - Supreme Court of South Carolina', 'id': 'sc' },
                { 'name': 'State Courts - Supreme Court of Vermont', 'id': 'vt' },
                { 'name': 'State Courts - Supreme Court of Virginia', 'id': 'va' },
                { 'name': 'State Courts - Supreme Judicial Court of Maine', 'id': 'me' },
                { 'name': 'State Courts - Tennessee Supreme Court', 'id': 'tenn' },
                { 'name': 'State Courts - Texas Supreme Court', 'id': 'tex' },
                { 'name': 'State Courts - Utah Supreme Court', 'id': 'utah' },
                { 'name': 'State Courts - Washington Supreme Court', 'id': 'wash' },
                { 'name': 'State Courts - West Virginia Supreme Court', 'id': 'wva' },
                { 'name': 'State Courts - Wisconsin Supreme Court', 'id': 'wis' },
                { 'name': 'State Courts - Wyoming Supreme Court', 'id': 'wyo' }]
            },
            {
                'hierarchy': 'Court of Appeal',
                'courts': [{ 'name': 'Air Force Court of Criminal Appeals', 'id': 'afcca' },
                { 'name': 'Armed Services Board of Contract Appeals', 'id': 'asbca' },
                { 'name': 'Army Court of Criminal Appeals', 'id': 'acca' },
                { 'name': 'Board of Immigration Appeals', 'id': 'bia' },
                { 'name': "Board of Veterans' Appeals", 'id': 'bva' },
                { 'name': 'Court of Appeals for the Armed Forces', 'id': 'armfor' },
                { 'name': 'Navy-Marine Corps Court of Criminal Appeals', 'id': 'nmcca' },
                { 'name': 'State Courts - California Court of Appeal', 'id': 'calctapp' },
                { 'name': 'State Courts - Colorado Court of Appeals', 'id': 'coloctapp' },
                {
                    'name': 'State Courts - Colorado Industrial Claim Appeals Office',
                    'id': 'coloworkcompcom'
                },
                {
                    'name': 'State Courts - Court of Appeals of Alaska',
                    'id': 'alaskactapp'
                },
                { 'name': 'State Courts - Court of Appeals of Arizona', 'id': 'arizctapp' },
                { 'name': 'State Courts - Court of Appeals of Arkansas', 'id': 'arkctapp' },
                { 'name': 'State Courts - Court of Appeals of Georgia', 'id': 'gactapp' },
                { 'name': 'State Courts - Court of Appeals of Iowa', 'id': 'iowactapp' },
                { 'name': 'State Courts - Court of Appeals of Kansas', 'id': 'kanctapp' },
                { 'name': 'State Courts - Court of Appeals of Kentucky', 'id': 'kyctapp' },
                { 'name': 'State Courts - Court of Appeals of Maryland', 'id': 'md' },
                {
                    'name': 'State Courts - Court of Appeals of Minnesota',
                    'id': 'minnctapp'
                },
                {
                    'name': 'State Courts - Court of Appeals of Mississippi',
                    'id': 'missctapp'
                },
                {
                    'name': 'State Courts - Court of Appeals of North Carolina',
                    'id': 'ncctapp'
                },
                { 'name': 'State Courts - Court of Appeals of Oregon', 'id': 'orctapp' },
                {
                    'name': 'State Courts - Court of Appeals of South Carolina',
                    'id': 'scctapp'
                },
                {
                    'name': 'State Courts - Court of Appeals of Tennessee',
                    'id': 'tennctapp'
                },
                { 'name': 'State Courts - Court of Appeals of Texas', 'id': 'texapp' },
                { 'name': 'State Courts - Court of Appeals of Utah', 'id': 'utahctapp' },
                { 'name': 'State Courts - Court of Appeals of Virginia', 'id': 'vactapp' },
                {
                    'name': 'State Courts - Court of Appeals of Washington',
                    'id': 'washctapp'
                },
                {
                    'name': 'State Courts - Court of Appeals of Wisconsin',
                    'id': 'wisctapp'
                },
                {
                    'name': 'State Courts - Court of Civil Appeals of Alabama',
                    'id': 'alacivapp'
                },
                {
                    'name': 'State Courts - Court of Civil Appeals of Oklahoma',
                    'id': 'oklacivapp'
                },
                {
                    'name': 'State Courts - Court of Criminal Appeals of Alabama',
                    'id': 'alacrimapp'
                },
                {
                    'name': 'State Courts - Court of Criminal Appeals of Oklahoma',
                    'id': 'oklacrimapp'
                },
                {
                    'name': 'State Courts - Court of Criminal Appeals of Tennessee',
                    'id': 'tenncrimapp'
                },
                {
                    'name': 'State Courts - Court of Criminal Appeals of Texas',
                    'id': 'texcrimapp'
                },
                {
                    'name': 'State Courts - Court of Special Appeals of Maryland',
                    'id': 'mdctspecapp'
                },
                {
                    'name': 'State Courts - District Court of Appeal of Florida',
                    'id': 'fladistctapp'
                },
                {
                    'name': 'State Courts - District of Columbia Court of Appeals',
                    'id': 'dc'
                },
                {
                    'name': 'State Courts - Hawaii Intermediate Court of Appeals',
                    'id': 'hawapp'
                },
                { 'name': 'State Courts - Idaho Court of Appeals', 'id': 'idahoctapp' },
                { 'name': 'State Courts - Indiana Court of Appeals', 'id': 'indctapp' },
                { 'name': 'State Courts - Louisiana Court of Appeal', 'id': 'lactapp' },
                { 'name': 'State Courts - Massachusetts Appeals Court', 'id': 'massappct' },
                { 'name': 'State Courts - Michigan Court of Appeals', 'id': 'michctapp' },
                { 'name': 'State Courts - Missouri Court of Appeals', 'id': 'moctapp' },
                { 'name': 'State Courts - Nebraska Court of Appeals', 'id': 'nebctapp' },
                { 'name': 'State Courts - New Mexico Court of Appeals', 'id': 'nmctapp' },
                { 'name': 'State Courts - New York Court of Appeals', 'id': 'ny' },
                { 'name': 'State Courts - North Dakota Court of Appeals', 'id': 'ndctapp' },
                { 'name': 'State Courts - Ohio Court of Appeals', 'id': 'ohioctapp' },
                {
                    'name': "State Courts - Tennessee Workers' Comp. Appeals Board",
                    'id': 'tennworkcompapp'
                }]
            },
            {
                'hierarchy': 'District Courts',
                'courts': [{ 'name': 'FEDERAL DISTRICT - C.D. California', 'id': 'cacd' },
                { 'name': 'FEDERAL DISTRICT - C.D. Illinois', 'id': 'ilcd' },
                { 'name': 'FEDERAL DISTRICT - D. Alaska', 'id': 'akd' },
                { 'name': 'FEDERAL DISTRICT - D. Arizona', 'id': 'azd' },
                { 'name': 'FEDERAL DISTRICT - D. Colorado', 'id': 'cod' },
                { 'name': 'FEDERAL DISTRICT - D. Connecticut', 'id': 'ctd' },
                { 'name': 'FEDERAL DISTRICT - D. Delaware', 'id': 'ded' },
                { 'name': 'FEDERAL DISTRICT - D. Guam', 'id': 'gud' },
                { 'name': 'FEDERAL DISTRICT - D. Hawaii', 'id': 'hid' },
                { 'name': 'FEDERAL DISTRICT - D. Idaho', 'id': 'idd' },
                { 'name': 'FEDERAL DISTRICT - D. Kansas', 'id': 'ksd' },
                { 'name': 'FEDERAL DISTRICT - D. Maine', 'id': 'med' },
                { 'name': 'FEDERAL DISTRICT - D. Maryland', 'id': 'mdd' },
                { 'name': 'FEDERAL DISTRICT - D. Massachusetts', 'id': 'mad' },
                { 'name': 'FEDERAL DISTRICT - D. Minnesota', 'id': 'mnd' },
                { 'name': 'FEDERAL DISTRICT - D. Montana', 'id': 'mtd' },
                { 'name': 'FEDERAL DISTRICT - D. Nebraska', 'id': 'ned' },
                { 'name': 'FEDERAL DISTRICT - D. Nevada', 'id': 'nvd' },
                { 'name': 'FEDERAL DISTRICT - D. New Hampshire', 'id': 'nhd' },
                { 'name': 'FEDERAL DISTRICT - D. New Jersey', 'id': 'njd' },
                { 'name': 'FEDERAL DISTRICT - D. New Mexico', 'id': 'nmd' },
                { 'name': 'FEDERAL DISTRICT - D. North Dakota', 'id': 'ndd' },
                { 'name': 'FEDERAL DISTRICT - D. Oregon', 'id': 'ord' },
                { 'name': 'FEDERAL DISTRICT - D. Puerto Rico', 'id': 'prd' },
                { 'name': 'FEDERAL DISTRICT - D. Rhode Island', 'id': 'rid' },
                { 'name': 'FEDERAL DISTRICT - D. South Carolina', 'id': 'scd' },
                { 'name': 'FEDERAL DISTRICT - D. South Dakota', 'id': 'sdd' },
                { 'name': 'FEDERAL DISTRICT - D. Utah', 'id': 'utd' },
                { 'name': 'FEDERAL DISTRICT - D. Vermont', 'id': 'vtd' },
                { 'name': 'FEDERAL DISTRICT - D. Wyoming', 'id': 'wyd' },
                { 'name': 'FEDERAL DISTRICT - District of Columbia', 'id': 'dcd' },
                { 'name': 'FEDERAL DISTRICT - E.D. Arkansas', 'id': 'ared' },
                { 'name': 'FEDERAL DISTRICT - E.D. California', 'id': 'caed' },
                { 'name': 'FEDERAL DISTRICT - E.D. Kentucky', 'id': 'kyed' },
                { 'name': 'FEDERAL DISTRICT - E.D. Louisiana', 'id': 'laed' },
                { 'name': 'FEDERAL DISTRICT - E.D. Michigan', 'id': 'mied' },
                { 'name': 'FEDERAL DISTRICT - E.D. Missouri', 'id': 'moed' },
                { 'name': 'FEDERAL DISTRICT - E.D. New York', 'id': 'nyed' },
                { 'name': 'FEDERAL DISTRICT - E.D. North Carolina', 'id': 'nced' },
                { 'name': 'FEDERAL DISTRICT - E.D. Oklahoma', 'id': 'oked' },
                { 'name': 'FEDERAL DISTRICT - E.D. Pennsylvania', 'id': 'paed' },
                { 'name': 'FEDERAL DISTRICT - E.D. Tennessee', 'id': 'tned' },
                { 'name': 'FEDERAL DISTRICT - E.D. Texas', 'id': 'txed' },
                { 'name': 'FEDERAL DISTRICT - E.D. Virginia', 'id': 'vaed' },
                { 'name': 'FEDERAL DISTRICT - E.D. Washington', 'id': 'waed' },
                { 'name': 'FEDERAL DISTRICT - E.D. Wisconsin', 'id': 'wied' },
                { 'name': 'FEDERAL DISTRICT - M.D. Alabama', 'id': 'almd' },
                { 'name': 'FEDERAL DISTRICT - M.D. Florida', 'id': 'flmd' },
                { 'name': 'FEDERAL DISTRICT - M.D. Georgia', 'id': 'gamd' },
                { 'name': 'FEDERAL DISTRICT - M.D. Louisiana', 'id': 'lamd' },
                { 'name': 'FEDERAL DISTRICT - M.D. North Carolina', 'id': 'ncmd' },
                { 'name': 'FEDERAL DISTRICT - M.D. Pennsylvania', 'id': 'pamd' },
                { 'name': 'FEDERAL DISTRICT - M.D. Tennessee', 'id': 'tnmd' },
                { 'name': 'FEDERAL DISTRICT - N.D. Alabama', 'id': 'alnd' },
                { 'name': 'FEDERAL DISTRICT - N.D. California', 'id': 'cand' },
                { 'name': 'FEDERAL DISTRICT - N.D. Florida', 'id': 'flnd' },
                { 'name': 'FEDERAL DISTRICT - N.D. Georgia', 'id': 'gand' },
                { 'name': 'FEDERAL DISTRICT - N.D. Illinois', 'id': 'ilnd' },
                { 'name': 'FEDERAL DISTRICT - N.D. Indiana', 'id': 'innd' },
                { 'name': 'FEDERAL DISTRICT - N.D. Iowa', 'id': 'iand' },
                { 'name': 'FEDERAL DISTRICT - N.D. Mississippi', 'id': 'msnd' },
                { 'name': 'FEDERAL DISTRICT - N.D. New York', 'id': 'nynd' },
                { 'name': 'FEDERAL DISTRICT - N.D. Ohio', 'id': 'ohnd' },
                { 'name': 'FEDERAL DISTRICT - N.D. Oklahoma', 'id': 'oknd' },
                { 'name': 'FEDERAL DISTRICT - N.D. Texas', 'id': 'txnd' },
                { 'name': 'FEDERAL DISTRICT - N.D. West Virginia', 'id': 'wvnd' },
                { 'name': 'FEDERAL DISTRICT - Northern Mariana Islands', 'id': 'nmid' },
                { 'name': 'FEDERAL DISTRICT - S.D. Alabama', 'id': 'alsd' },
                { 'name': 'FEDERAL DISTRICT - S.D. California', 'id': 'casd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Florida', 'id': 'flsd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Georgia', 'id': 'gasd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Illinois', 'id': 'ilsd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Indiana', 'id': 'insd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Iowa', 'id': 'iasd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Mississippi', 'id': 'mssd' },
                { 'name': 'FEDERAL DISTRICT - S.D. New York', 'id': 'nysd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Ohio', 'id': 'ohsd' },
                { 'name': 'FEDERAL DISTRICT - S.D. Texas', 'id': 'txsd' },
                { 'name': 'FEDERAL DISTRICT - S.D. West Virginia', 'id': 'wvsd' },
                { 'name': 'FEDERAL DISTRICT - Virgin Islands', 'id': 'vid' },
                { 'name': 'FEDERAL DISTRICT - W.D. Arkansas', 'id': 'arwd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Kentucky', 'id': 'kywd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Louisiana', 'id': 'lawd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Michigan', 'id': 'miwd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Missouri', 'id': 'mowd' },
                { 'name': 'FEDERAL DISTRICT - W.D. New York', 'id': 'nywd' },
                { 'name': 'FEDERAL DISTRICT - W.D. North Carolina', 'id': 'ncwd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Oklahoma', 'id': 'okwd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Pennsylvania', 'id': 'pawd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Tennessee', 'id': 'tnwd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Texas', 'id': 'txwd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Virginia', 'id': 'vawd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Washington', 'id': 'wawd' },
                { 'name': 'FEDERAL DISTRICT - W.D. Wisconsin', 'id': 'wiwd' }]
            },
            {
                'hierarchy': 'Others',
                'courts': [{ 'name': 'Attorneys General', 'id': 'ag' },
                { 'name': 'Bankruptcy Courts - C.D. California', 'id': 'cacb' },
                { 'name': 'Bankruptcy Courts - C.D. Illinois', 'id': 'ilcb' },
                { 'name': 'Bankruptcy Courts - D. Alaska', 'id': 'akb' },
                { 'name': 'Bankruptcy Courts - D. Arizona', 'id': 'arb' },
                { 'name': 'Bankruptcy Courts - D. Colorado', 'id': 'cob' },
                { 'name': 'Bankruptcy Courts - D. Connecticut', 'id': 'ctb' },
                { 'name': 'Bankruptcy Courts - D. Delaware', 'id': 'deb' },
                { 'name': 'Bankruptcy Courts - D. Guam', 'id': 'gub' },
                { 'name': 'Bankruptcy Courts - D. Hawaii', 'id': 'hib' },
                { 'name': 'Bankruptcy Courts - D. Idaho', 'id': 'idb' },
                { 'name': 'Bankruptcy Courts - D. Kansas', 'id': 'ksb' },
                { 'name': 'Bankruptcy Courts - D. Maine', 'id': 'bapme' },
                { 'name': 'Bankruptcy Courts - D. Maine', 'id': 'meb' },
                { 'name': 'Bankruptcy Courts - D. Maryland', 'id': 'mdb' },
                { 'name': 'Bankruptcy Courts - D. Massachusetts', 'id': 'mab' },
                { 'name': 'Bankruptcy Courts - D. Minnesota', 'id': 'mnb' },
                { 'name': 'Bankruptcy Courts - D. Montana', 'id': 'mtb' },
                { 'name': 'Bankruptcy Courts - D. Nebraska', 'id': 'nebraskab' },
                { 'name': 'Bankruptcy Courts - D. Nevada', 'id': 'nvb' },
                { 'name': 'Bankruptcy Courts - D. New Hampshire', 'id': 'nhb' },
                { 'name': 'Bankruptcy Courts - D. New Jersey', 'id': 'njb' },
                { 'name': 'Bankruptcy Courts - D. New Mexico', 'id': 'nmb' },
                { 'name': 'Bankruptcy Courts - D. North Dakota', 'id': 'ndb' },
                { 'name': 'Bankruptcy Courts - D. Oregon', 'id': 'orb' },
                { 'name': 'Bankruptcy Courts - D. Puerto Rico', 'id': 'prb' },
                { 'name': 'Bankruptcy Courts - D. Rhode Island', 'id': 'rib' },
                { 'name': 'Bankruptcy Courts - D. South Carolina', 'id': 'scb' },
                { 'name': 'Bankruptcy Courts - D. South Dakota', 'id': 'sdb' },
                { 'name': 'Bankruptcy Courts - D. Utah', 'id': 'utb' },
                { 'name': 'Bankruptcy Courts - D. Vermont', 'id': 'vtb' },
                { 'name': 'Bankruptcy Courts - D. Virgin Islands', 'id': 'vib' },
                { 'name': 'Bankruptcy Courts - D. Wyoming', 'id': 'wyb' },
                { 'name': 'Bankruptcy Courts - District of Columbia', 'id': 'dcb' },
                { 'name': 'Bankruptcy Courts - E.D. Arkansas', 'id': 'areb' },
                { 'name': 'Bankruptcy Courts - E.D. California', 'id': 'caeb' },
                { 'name': 'Bankruptcy Courts - E.D. Kentucky', 'id': 'kyeb' },
                { 'name': 'Bankruptcy Courts - E.D. Louisiana', 'id': 'laeb' },
                { 'name': 'Bankruptcy Courts - E.D. Michigan', 'id': 'mieb' },
                { 'name': 'Bankruptcy Courts - E.D. Missouri', 'id': 'moeb' },
                { 'name': 'Bankruptcy Courts - E.D. New York', 'id': 'nyeb' },
                { 'name': 'Bankruptcy Courts - E.D. North Carolina', 'id': 'nceb' },
                { 'name': 'Bankruptcy Courts - E.D. Oklahoma', 'id': 'okeb' },
                { 'name': 'Bankruptcy Courts - E.D. Pennsylvania', 'id': 'paeb' },
                { 'name': 'Bankruptcy Courts - E.D. Tennessee', 'id': 'tneb' },
                { 'name': 'Bankruptcy Courts - E.D. Texas', 'id': 'txeb' },
                { 'name': 'Bankruptcy Courts - E.D. Virginia', 'id': 'vaeb' },
                { 'name': 'Bankruptcy Courts - E.D. Washington', 'id': 'waeb' },
                { 'name': 'Bankruptcy Courts - E.D. Wisconsin', 'id': 'wieb' },
                { 'name': 'Bankruptcy Courts - Eighth Circuit', 'id': 'bap8' },
                { 'name': 'Bankruptcy Courts - First Circuit', 'id': 'bap1' },
                { 'name': 'Bankruptcy Courts - M.D. Alabama', 'id': 'almb' },
                { 'name': 'Bankruptcy Courts - M.D. Florida', 'id': 'flmb' },
                { 'name': 'Bankruptcy Courts - M.D. Georgia', 'id': 'gamb' },
                { 'name': 'Bankruptcy Courts - M.D. Louisiana', 'id': 'lamb' },
                { 'name': 'Bankruptcy Courts - M.D. North Carolina', 'id': 'ncmb' },
                { 'name': 'Bankruptcy Courts - M.D. Pennsylvania', 'id': 'pamb' },
                { 'name': 'Bankruptcy Courts - M.D. Tennessee', 'id': 'tnmb' },
                { 'name': 'Bankruptcy Courts - Massachusetts', 'id': 'bapma' },
                { 'name': 'Bankruptcy Courts - N.D. Alabama', 'id': 'alnb' },
                { 'name': 'Bankruptcy Courts - N.D. California', 'id': 'canb' },
                { 'name': 'Bankruptcy Courts - N.D. Florida', 'id': 'flnb' },
                { 'name': 'Bankruptcy Courts - N.D. Georgia', 'id': 'ganb' },
                { 'name': 'Bankruptcy Courts - N.D. Illinois', 'id': 'ilnb' },
                { 'name': 'Bankruptcy Courts - N.D. Indiana', 'id': 'innb' },
                { 'name': 'Bankruptcy Courts - N.D. Iowa', 'id': 'ianb' },
                { 'name': 'Bankruptcy Courts - N.D. Mississippi', 'id': 'msnb' },
                { 'name': 'Bankruptcy Courts - N.D. New York', 'id': 'nynb' },
                { 'name': 'Bankruptcy Courts - N.D. Ohio', 'id': 'ohnb' },
                { 'name': 'Bankruptcy Courts - N.D. Oklahoma', 'id': 'oknb' },
                { 'name': 'Bankruptcy Courts - N.D. Texas', 'id': 'txnb' },
                { 'name': 'Bankruptcy Courts - N.D. West Virginia', 'id': 'wvnb' },
                { 'name': 'Bankruptcy Courts - Ninth Circuit', 'id': 'bap9' },
                { 'name': 'Bankruptcy Courts - Northern Mariana Islands', 'id': 'nmib' },
                { 'name': 'Bankruptcy Courts - S.D. Alabama', 'id': 'alsb' },
                { 'name': 'Bankruptcy Courts - S.D. California', 'id': 'casb' },
                { 'name': 'Bankruptcy Courts - S.D. Florida', 'id': 'flsb' },
                { 'name': 'Bankruptcy Courts - S.D. Georgia', 'id': 'gasb' },
                { 'name': 'Bankruptcy Courts - S.D. Illinois', 'id': 'ilsb' },
                { 'name': 'Bankruptcy Courts - S.D. Indiana', 'id': 'insb' },
                { 'name': 'Bankruptcy Courts - S.D. Iowa', 'id': 'iasb' },
                { 'name': 'Bankruptcy Courts - S.D. Mississippi', 'id': 'mssb' },
                { 'name': 'Bankruptcy Courts - S.D. New York', 'id': 'nysb' },
                { 'name': 'Bankruptcy Courts - S.D. Ohio', 'id': 'ohsb' },
                { 'name': 'Bankruptcy Courts - S.D. Texas', 'id': 'txsb' },
                { 'name': 'Bankruptcy Courts - S.D. West Virginia', 'id': 'wvsb' },
                { 'name': 'Bankruptcy Courts - Second Circuit', 'id': 'bap2' },
                { 'name': 'Bankruptcy Courts - Sixth Circuit', 'id': 'bap6' },
                { 'name': 'Bankruptcy Courts - Tenth Circuit', 'id': 'bap10' },
                { 'name': 'Bankruptcy Courts - W.D. Arkansas', 'id': 'arwb' },
                { 'name': 'Bankruptcy Courts - W.D. Kentucky', 'id': 'kywb' },
                { 'name': 'Bankruptcy Courts - W.D. Louisiana', 'id': 'lawb' },
                { 'name': 'Bankruptcy Courts - W.D. Michigan', 'id': 'miwb' },
                { 'name': 'Bankruptcy Courts - W.D. Missouri', 'id': 'mowb' },
                { 'name': 'Bankruptcy Courts - W.D. New York', 'id': 'nywb' },
                { 'name': 'Bankruptcy Courts - W.D. North Carolina', 'id': 'ncwb' },
                { 'name': 'Bankruptcy Courts - W.D. Oklahoma', 'id': 'okwb' },
                { 'name': 'Bankruptcy Courts - W.D. Pennsylvania', 'id': 'pawb' },
                { 'name': 'Bankruptcy Courts - W.D. Tennessee', 'id': 'tnwb' },
                { 'name': 'Bankruptcy Courts - W.D. Texas', 'id': 'txwb' },
                { 'name': 'Bankruptcy Courts - W.D. Virginia', 'id': 'vawb' },
                { 'name': 'Bankruptcy Courts - W.D. Washington', 'id': 'wawb' },
                { 'name': 'Bankruptcy Courts - W.D. Wisconsin', 'id': 'wiwb' },
                { 'name': 'Court of International Trade', 'id': 'cit' },
                { 'name': 'FEDERAL APPELLATE - D.C. Circuit', 'id': 'cadc' },
                { 'name': 'FEDERAL APPELLATE - Eighth Circuit', 'id': 'ca8' },
                { 'name': 'FEDERAL APPELLATE - Eleventh Circuit', 'id': 'ca11' },
                { 'name': 'FEDERAL APPELLATE - Federal Circuit', 'id': 'cafc' },
                { 'name': 'FEDERAL APPELLATE - Fifth Circuit', 'id': 'ca5' },
                { 'name': 'FEDERAL APPELLATE - First Circuit', 'id': 'ca1' },
                { 'name': 'FEDERAL APPELLATE - Fourth Circuit', 'id': 'ca4' },
                { 'name': 'FEDERAL APPELLATE - Ninth Circuit', 'id': 'ca9' },
                { 'name': 'FEDERAL APPELLATE - Second Circuit', 'id': 'ca2' },
                { 'name': 'FEDERAL APPELLATE - Seventh Circuit', 'id': 'ca7' },
                { 'name': 'FEDERAL APPELLATE - Sixth Circuit', 'id': 'ca6' },
                { 'name': 'FEDERAL APPELLATE - Tenth Circuit', 'id': 'ca10' },
                { 'name': 'FEDERAL APPELLATE - Third Circuit', 'id': 'ca3' },
                { 'name': 'Federal Claims', 'id': 'uscfc' },
                { 'name': 'Foreign Intelligence Surveillance Court', 'id': 'fisc' },
                {
                    'name': 'Foreign Intelligence Surveillance Court of Review',
                    'id': 'fiscr'
                },
                { 'name': 'Judicial Panel on Multidistrict Litigation', 'id': 'jpml' },
                { 'name': 'Merit Systems Protection Board', 'id': 'mspb' },
                { 'name': 'Military Commission Review', 'id': 'mc' },
                { 'name': 'Office of Legal Counsel', 'id': 'olc' },
                { 'name': 'State Courts - Appellate Court of Illinois', 'id': 'illappct' },
                {
                    'name': 'State Courts - Appellate Division of the Superior Court of California',
                    'id': 'calappdeptsuper'
                },
                { 'name': 'State Courts - Arizona Tax Court', 'id': 'ariztaxct' },
                {
                    'name': 'State Courts - Arkansas Attorney General Reports',
                    'id': 'arkag'
                },
                {
                    'name': "State Courts - Arkansas Workers' Compensation Commission",
                    'id': 'arkworkcompcom'
                },
                {
                    'name': 'State Courts - California Attorney General Reports',
                    'id': 'calag'
                },
                {
                    'name': 'State Courts - Civil Court of the City of New York',
                    'id': 'nycivct'
                },
                {
                    'name': 'State Courts - Colorado Attorney General Reports',
                    'id': 'coloag'
                },
                {
                    'name': 'State Courts - Commonwealth Court of Pennsylvania',
                    'id': 'pacommwct'
                },
                { 'name': 'State Courts - Connecticut Appellate Court', 'id': 'connappct' },
                {
                    'name': 'State Courts - Connecticut Compensation Review Board',
                    'id': 'connworkcompcom'
                },
                {
                    'name': 'State Courts - Connecticut Superior Court',
                    'id': 'connsuperct'
                },
                { 'name': 'State Courts - Court of Chancery of Delaware', 'id': 'delch' },
                {
                    'name': 'State Courts - Court on the Judiciary of Delaware',
                    'id': 'deljudct'
                },
                {
                    'name': 'State Courts - Court on the Judiciary of Oklahoma',
                    'id': 'oklacoj'
                },
                {
                    'name': 'State Courts - Criminal Court of the City of New York',
                    'id': 'nycrimct'
                },
                {
                    'name': 'State Courts - Delaware Court of Common Pleas',
                    'id': 'delctcompl'
                },
                { 'name': 'State Courts - Delaware Family Court', 'id': 'delfamct' },
                {
                    'name': 'State Courts - Florida Attorney General Reports',
                    'id': 'flaag'
                },
                { 'name': 'State Courts - Indiana Tax Court', 'id': 'indtc' },
                {
                    'name': 'State Courts - Judicial Discipline of Pennsylvania',
                    'id': 'cjdpa'
                },
                { 'name': 'State Courts - Kansas Attorney General Reports', 'id': 'kanag' },
                {
                    'name': 'State Courts - Louisiana Attorney General Reports',
                    'id': 'laag'
                },
                {
                    'name': 'State Courts - Maryland Attorney General Reports',
                    'id': 'mdag'
                },
                {
                    'name': 'State Courts - Massachusetts Department of Industrial Accidents',
                    'id': 'maworkcompcom'
                },
                {
                    'name': 'State Courts - Massachusetts District Court',
                    'id': 'massdistct'
                },
                { 'name': 'State Courts - Massachusetts Land Court', 'id': 'masslandct' },
                {
                    'name': 'State Courts - Massachusetts Superior Court',
                    'id': 'masssuperct'
                },
                {
                    'name': 'State Courts - Minnesota Attorney General Reports',
                    'id': 'minnag'
                },
                {
                    'name': 'State Courts - Missouri Attorney General Reports',
                    'id': 'moag'
                },
                {
                    'name': 'State Courts - Montana Attorney General Reports',
                    'id': 'montag'
                },
                { 'name': 'State Courts - Montana Tax Appeal Board', 'id': 'monttc' },
                {
                    'name': 'State Courts - Nebraska Attorney General Reports',
                    'id': 'nebag'
                },
                {
                    'name': 'State Courts - New Jersey Superior Court App Division',
                    'id': 'njsuperctappdiv'
                },
                { 'name': 'State Courts - New Jersey Tax Court', 'id': 'njtaxct' },
                {
                    'name': 'State Courts - New York Attorney General Reports',
                    'id': 'nyag'
                },
                { 'name': 'State Courts - New York Family Court', 'id': 'nyfamct' },
                { 'name': "State Courts - New York Surrogate's Court", 'id': 'nysurct' },
                {
                    'name': 'State Courts - North Carolina Industrial Commission',
                    'id': 'ncworkcompcom'
                },
                { 'name': 'State Courts - Ohio Court of Claims', 'id': 'ohioctcl' },
                {
                    'name': 'State Courts - Oklahoma Attorney General Reports',
                    'id': 'oklaag'
                },
                {
                    'name': 'State Courts - Oklahoma Judicial Ethics Advisory Panel',
                    'id': 'oklajeap'
                },
                { 'name': 'State Courts - Oregon Tax Court', 'id': 'ortc' },
                { 'name': 'State Courts - Superior Court of Delaware', 'id': 'delsuperct' },
                {
                    'name': 'State Courts - Superior Court of North Carolina',
                    'id': 'ncsuperct'
                },
                {
                    'name': 'State Courts - Superior Court of Pennsylvania',
                    'id': 'pasuperct'
                },
                {
                    'name': 'State Courts - Superior Court of Rhode Island',
                    'id': 'risuperct'
                },
                {
                    'name': "State Courts - Tennessee Court of Workers' Comp. Claims",
                    'id': 'tennworkcompcl'
                },
                { 'name': 'State Courts - Texas Attorney General Reports', 'id': 'texag' },
                {
                    'name': 'State Courts - Texas Judicial Panel on Multidistrict Litigation',
                    'id': 'texjpml'
                },
                {
                    'name': 'State Courts - Texas Special Court of Review',
                    'id': 'texreview'
                },
                { 'name': 'State Courts - Vermont Superior Court', 'id': 'vtsuperct' },
                {
                    'name': 'State Courts - Washington Attorney General Reports',
                    'id': 'washag'
                },
                {
                    'name': 'State Courts - Wisconsin Attorney General Reports',
                    'id': 'wisag'
                },
                { 'name': 'U.S. Judicial Conference Committee', 'id': 'usjc' },
                { 'name': 'U.S. Tax Court', 'id': 'tax' },
                { 'name': 'Veterans Claims', 'id': 'cavc' }]
            }]
        },

        "Canada": {
            "Alphabet": [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "H",
                "L",
                "M",
                "N",
                "O",
                "P",
                "S",
                "T",
                "W",
                "Y"
            ],
            "courts": [
                {
                    "hierarchy": "Supreme Courts",
                    "courts": [
                        {
                            "name": "Supreme Court of British Columbia",
                            "id": "21"
                        },
                        {
                            "name": "Supreme Court of Canada",
                            "id": "1"
                        },
                        {
                            "name": "Supreme Court of Canada - Applications for Leave",
                            "id": "2"
                        },
                        {
                            "name": "Supreme Court of Newfoundland and Labrador",
                            "id": "99"
                        },
                        {
                            "name": "Supreme Court of Nova Scotia",
                            "id": "87"
                        },
                        {
                            "name": "Supreme Court of Nova Scotia (Family Division)",
                            "id": "88"
                        },
                        {
                            "name": "Supreme Court of Prince Edward Island",
                            "id": "96"
                        },
                        {
                            "name": "Supreme Court of Yukon",
                            "id": "102"
                        },
                        {
                            "name": "Supreme Court of the Northwest Territories",
                            "id": "106"
                        }
                    ]
                },
                {
                    "hierarchy": "Court Martial Appeal Courts",
                    "courts": [
                        {
                            "name": "Court Martial Appeal Court of Canada",
                            "id": "7"
                        },
                        {
                            "name": "Courts Martial",
                            "id": "8"
                        }
                    ]
                },
                {
                    "hierarchy": "Provincial/Territorial Courts of Appeal",
                    "courts": [
                        {
                            "name": "Court of Appeal for British Columbia",
                            "id": "20"
                        },
                        {
                            "name": "Court of Appeal for Ontario",
                            "id": "45"
                        },
                        {
                            "name": "Court of Appeal for Saskatchewan",
                            "id": "34"
                        },
                        {
                            "name": "Court of Appeal for the Northwest Territories",
                            "id": "105"
                        },
                        {
                            "name": "Court of Appeal of Alberta",
                            "id": "29"
                        },
                        {
                            "name": "Court of Appeal of Manitoba",
                            "id": "42"
                        },
                        {
                            "name": "Court of Appeal of New Brunswick",
                            "id": "81"
                        },
                        {
                            "name": "Court of Appeal of Newfoundland and Labrador",
                            "id": "98"
                        },
                        {
                            "name": "Court of Appeal of Nunavut",
                            "id": "111"
                        },
                        {
                            "name": "Court of Appeal of Quebec",
                            "id": "67"
                        },
                        {
                            "name": "Court of Appeal of Yukon",
                            "id": "101"
                        },
                        {
                            "name": "Nova Scotia Court of Appeal",
                            "id": "86"
                        },
                        {
                            "name": "Prince Edward Island Court of Appeal",
                            "id": "95"
                        }
                    ]
                },
                {
                    "hierarchy": "Federal Court of Appeal",
                    "courts": [
                        {
                            "name": "Federal Court of Appeal",
                            "id": "3"
                        }
                    ]
                },

                {
                    "hierarchy": "Provincial/Territorial Superior Courts",
                    "courts": [
                        {
                            "name": "Provincial Court",
                            "id": "83"
                        },
                        {
                            "name": "Provincial Court of British Columbia",
                            "id": "22"
                        },
                        {
                            "name": "Provincial Court of Manitoba",
                            "id": "44"
                        },
                        {
                            "name": "Provincial Court of Newfoundland and Labrador",
                            "id": "100"
                        },
                        {
                            "name": "Provincial Court of Nova Scotia",
                            "id": "89"
                        },
                        {
                            "name": "Provincial Court of Prince Edward Island",
                            "id": "97"
                        },
                        {
                            "name": "Provincial Court of Saskatchewan",
                            "id": "36"
                        },
                        {
                            "name": "Superior Court",
                            "id": "68"
                        },
                        {
                            "name": "Superior Court of Justice",
                            "id": "46"
                        },
                        {
                            "name": "Territorial Court of Yukon",
                            "id": "103"
                        },
                        {
                            "name": "Territorial Court of the Northwest Territories",
                            "id": "107"
                        }
                    ]
                },
                {
                    "hierarchy": "Federal Court",
                    "courts": [
                        {
                            "name": "Federal Court",
                            "id": "4"
                        }
                    ]
                },
                {
                    "hierarchy": "Tax Court",
                    "courts": [
                        {
                            "name": "Tax Court of Canada",
                            "id": "5"
                        }
                    ]
                },
                {
                    "hierarchy": "Tribunals",
                    "courts": [
                        {
                            "name": "Administrative Tribunal of Québec",
                            "id": "73"
                        },
                        {
                            "name": "Agriculture, Food & Rural Affairs Appeal Tribunal",
                            "id": "50"
                        },
                        {
                            "name": "Alberta Land and Property Rights Tribunal",
                            "id": "32"
                        },
                        {
                            "name": "Appeal Tribunal under the Medical Profession Act",
                            "id": "40"
                        },
                        {
                            "name": "British Columbia Employment Standards Tribunal",
                            "id": "23"
                        },
                        {
                            "name": "British Columbia Human Rights Tribunal",
                            "id": "24"
                        },
                        {
                            "name": "British Columbia Workers' Compensation Appeal Tribunal",
                            "id": "25"
                        },
                        {
                            "name": "Canada Agricultural Review Tribunal",
                            "id": "9"
                        },
                        {
                            "name": "Canadian Human Rights Tribunal",
                            "id": "10"
                        },
                        {
                            "name": "Canadian International Trade Tribunal",
                            "id": "11"
                        },
                        {
                            "name": "Capital Markets Tribunal",
                            "id": "51"
                        },
                        {
                            "name": "Civil Resolution Tribunal of British Columbia",
                            "id": "26"
                        },
                        {
                            "name": "Competition Tribunal",
                            "id": "12"
                        },
                        {
                            "name": "Condominium Authority Tribunal",
                            "id": "52"
                        },
                        {
                            "name": "Energy Resource Appeal Tribunal",
                            "id": "27"
                        },
                        {
                            "name": "Environmental Protection Tribunal of Canada",
                            "id": "13"
                        },
                        {
                            "name": "Environmental Review Tribunal",
                            "id": "53"
                        },
                        {
                            "name": "Financial Services Tribunal",
                            "id": "28"
                        },
                        {
                            "name": "Financial Services Tribunal",
                            "id": "54"
                        },
                        {
                            "name": "Financial and Consumer Services Tribunal",
                            "id": "84"
                        },
                        {
                            "name": "Horse Racing Alberta Appeal Tribunal",
                            "id": "33"
                        },
                        {
                            "name": "Human Rights Tribunal",
                            "id": "70"
                        },
                        {
                            "name": "Human Rights Tribunal of Ontario",
                            "id": "55"
                        },
                        {
                            "name": "Law Society Tribunal",
                            "id": "56"
                        },
                        {
                            "name": "Local Planning Appeal Tribunal",
                            "id": "57"
                        },
                        {
                            "name": "Mining and Lands Tribunal",
                            "id": "58"
                        },
                        {
                            "name": "Northwest Territories Assessment Appeal Tribunal",
                            "id": "110"
                        },
                        {
                            "name": "Northwest Territories and Nunavut Workers’ Compensation Appeals Tribunal",
                            "id": "109"
                        },
                        {
                            "name": "Northwest Territories and Nunavut Workers’ Compensation Appeals Tribunal",
                            "id": "114"
                        },
                        {
                            "name": "Nova Scotia Labour Standards Tribunal",
                            "id": "93"
                        },
                        {
                            "name": "Nova Scotia Workers' Compensation Appeals Tribunal",
                            "id": "94"
                        },
                        {
                            "name": "Nunavut Human Rights Tribunal",
                            "id": "115"
                        },
                        {
                            "name": "Occupational Health and Safety Tribunal Canada",
                            "id": "14"
                        },
                        {
                            "name": "Ontario Land Tribunal",
                            "id": "60"
                        },
                        {
                            "name": "Ontario Licence Appeal Tribunal",
                            "id": "61"
                        },
                        {
                            "name": "Ontario Pay Equity Hearings Tribunal",
                            "id": "62"
                        },
                        {
                            "name": "Ontario Physicians and Surgeons Discipline Tribunal",
                            "id": "63"
                        },
                        {
                            "name": "Ontario Social Benefits Tribunal",
                            "id": "64"
                        },
                        {
                            "name": "Ontario Special Education (English) Tribunal",
                            "id": "65"
                        },
                        {
                            "name": "Ontario Workplace Safety and Insurance Appeals Tribunal",
                            "id": "66"
                        },
                        {
                            "name": "Professions Tribunal",
                            "id": "71"
                        },
                        {
                            "name": "Public Servants Disclosure Protection Tribunal",
                            "id": "15"
                        },
                        {
                            "name": "Public Service Staffing Tribunal",
                            "id": "16"
                        },
                        {
                            "name": "Saskatchewan Human Rights Tribunal",
                            "id": "41"
                        },
                        {
                            "name": "Social Security Tribunal of Canada",
                            "id": "17"
                        },
                        {
                            "name": "Specific Claims Tribunal Canada",
                            "id": "18"
                        },
                        {
                            "name": "Transportation Appeal Tribunal of Canada",
                            "id": "19"
                        },
                        {
                            "name": "Tribunal administratif des marchés financiers",
                            "id": "76"
                        },
                        {
                            "name": "Tribunal administratif du logement",
                            "id": "77"
                        },
                        {
                            "name": "Tribunal administratif du travail",
                            "id": "78"
                        },
                        {
                            "name": "Tribunal d'arbitrage (RQ and CARRA)",
                            "id": "80"
                        },
                        {
                            "name": "Tribunal d'arbitrage (performing, recording and film artists)",
                            "id": "79"
                        },
                        {
                            "name": "Workers’ Compensation Appeals Tribunal",
                            "id": "85"
                        }
                    ]
                },
                {
                    "hierarchy": "Others",
                    "courts": [
                        {
                            "name": "Alberta Court of Justice",
                            "id": "31"
                        },
                        {
                            "name": "Court of King's Bench for Saskatchewan",
                            "id": "35"
                        },
                        {
                            "name": "Court of King's Bench of Alberta",
                            "id": "30"
                        },
                        {
                            "name": "Court of King's Bench of Manitoba",
                            "id": "43"
                        },
                        {
                            "name": "Court of King's Bench of New Brunswick",
                            "id": "82"
                        },
                        {
                            "name": "Court of Quebec",
                            "id": "69"
                        },
                        {
                            "name": "Divisional Court",
                            "id": "47"
                        },
                        {
                            "name": "Exchequer Court of Canada",
                            "id": "6"
                        },
                        {
                            "name": "Labour Court",
                            "id": "75"
                        },
                        {
                            "name": "Municipal Courts",
                            "id": "72"
                        },
                        {
                            "name": "Nova Scotia Family Court",
                            "id": "92"
                        },
                        {
                            "name": "Nova Scotia Probate Court",
                            "id": "91"
                        },
                        {
                            "name": "Nunavut Court of Justice",
                            "id": "112"
                        },
                        {
                            "name": "Ontario Court of Justice",
                            "id": "49"
                        },
                        {
                            "name": "Ontario Court of the Drainage Referee",
                            "id": "59"
                        },
                        {
                            "name": "Saskatchewan District Court",
                            "id": "37"
                        },
                        {
                            "name": "Saskatchewan Surrogate Court",
                            "id": "38"
                        },
                        {
                            "name": "Saskatchewan Unified Family Court",
                            "id": "39"
                        },
                        {
                            "name": "Small Claims Court",
                            "id": "48"
                        },
                        {
                            "name": "Small Claims Court",
                            "id": "90"
                        },
                        {
                            "name": "Small Claims Court of the Yukon",
                            "id": "104"
                        },
                        {
                            "name": "Youth Justice Court",
                            "id": "108"
                        },
                        {
                            "name": "Youth Justice Court of Nunavut",
                            "id": "113"
                        }
                    ]
                }
            ]

        }
    }